export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601Date: { input: string; output: string };
  ISO8601DateTime: { input: string; output: string };
};

export type BracketContestInputObject = {
  away: BracketContestTeamInputObject;
  date?: InputMaybe<Scalars['String']['input']>;
  gameNumber: Scalars['Int']['input'];
  home: BracketContestTeamInputObject;
  isNeutral?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  roundId: BracketRoundIdEnum;
  secondaryStatusId?: InputMaybe<Scalars['Int']['input']>;
  seriesContests?: InputMaybe<Array<BracketSeriesInputObject>>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

export type BracketContestTeamInputObject = {
  isBye?: InputMaybe<Scalars['Boolean']['input']>;
  isOriginal?: InputMaybe<Scalars['Boolean']['input']>;
  isTbd?: InputMaybe<Scalars['Boolean']['input']>;
  lineScore?: InputMaybe<Array<Scalars['Int']['input']>>;
  score?: InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  seriesTeamInfo?: InputMaybe<Array<BracketSeriesTeamInputObject>>;
  shootoutScore?: InputMaybe<Scalars['Int']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export enum BracketMatchupTypeEnum {
  BEST_OF_FIVE = 'BEST_OF_FIVE',
  BEST_OF_THREE = 'BEST_OF_THREE',
  SINGLE_GAME = 'SINGLE_GAME'
}

export type BracketQuadrantInputObject = {
  index?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum BracketRoundIdEnum {
  CHAMPIONSHIP_ROUND = 'CHAMPIONSHIP_ROUND',
  EIGHTH_LOSER_ROUND = 'EIGHTH_LOSER_ROUND',
  EXTRA_ROUND = 'EXTRA_ROUND',
  FIFTH_LOSER_ROUND = 'FIFTH_LOSER_ROUND',
  FINAL_ROUND = 'FINAL_ROUND',
  FIRST_LOSER_ROUND = 'FIRST_LOSER_ROUND',
  FIRST_ROUND = 'FIRST_ROUND',
  FOURTH_LOSER_ROUND = 'FOURTH_LOSER_ROUND',
  FOURTH_ROUND = 'FOURTH_ROUND',
  PLAY_IN_ROUND = 'PLAY_IN_ROUND',
  SECOND_LOSER_ROUND = 'SECOND_LOSER_ROUND',
  SECOND_ROUND = 'SECOND_ROUND',
  SEMI_FINAL_ROUND = 'SEMI_FINAL_ROUND',
  SEVENTH_LOSER_ROUND = 'SEVENTH_LOSER_ROUND',
  SIXTH_LOSER_ROUND = 'SIXTH_LOSER_ROUND',
  THIRD_LOSER_ROUND = 'THIRD_LOSER_ROUND',
  THIRD_PLACE_ROUND = 'THIRD_PLACE_ROUND',
  THIRD_ROUND = 'THIRD_ROUND',
  WINNER_FINAL_ROUND = 'WINNER_FINAL_ROUND',
  WINNER_SEMI_FINAL_ROUND = 'WINNER_SEMI_FINAL_ROUND'
}

export type BracketRoundInputObject = {
  bracketContests: Array<BracketContestInputObject>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id: BracketRoundIdEnum;
  isLoserRound?: InputMaybe<Scalars['Boolean']['input']>;
  isNeutral?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export enum BracketRoundStatusEnum {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING'
}

export type BracketSeriesInputObject = {
  date?: InputMaybe<Scalars['String']['input']>;
  secondaryStatusId?: InputMaybe<Scalars['Int']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

export type BracketSeriesTeamInputObject = {
  lineScore?: InputMaybe<Array<Scalars['Int']['input']>>;
  score?: InputMaybe<Scalars['Int']['input']>;
  shootoutScore?: InputMaybe<Scalars['Int']['input']>;
};

export type ClaimableEntity = {
  id: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  type: ClaimableEntityType;
};

export enum ClaimableEntityType {
  /** A player */
  Player = 'Player',
  /** A school */
  School = 'School',
  /** A team */
  Team = 'Team'
}

export enum CoachTypeEnum {
  ACADEMIC_COORDINATOR = 'ACADEMIC_COORDINATOR',
  ADMINISTRATIVE_ASSISTANT = 'ADMINISTRATIVE_ASSISTANT',
  ASSISTANT_ATHLETIC_DIRECTOR = 'ASSISTANT_ATHLETIC_DIRECTOR',
  ASSISTANT_COACH = 'ASSISTANT_COACH',
  ASSOCIATE_HEAD_COACH = 'ASSOCIATE_HEAD_COACH',
  ATHLETIC_DIRECTOR = 'ATHLETIC_DIRECTOR',
  ATHLETIC_TRAINER = 'ATHLETIC_TRAINER',
  BAND_DIRECTOR = 'BAND_DIRECTOR',
  CHEERLEADING_COORDINATOR = 'CHEERLEADING_COORDINATOR',
  DIRECTOR_OF_OPERATIONS = 'DIRECTOR_OF_OPERATIONS',
  HEAD_COACH = 'HEAD_COACH',
  PRINCIPAL = 'PRINCIPAL',
  STUDENT_MANAGER = 'STUDENT_MANAGER',
  VICE_PRINCIPAL = 'VICE_PRINCIPAL',
  VIDEO_COORDINATOR = 'VIDEO_COORDINATOR'
}

export enum ContentItemTopicEnum {
  ATHLETE_OF_THE_WEEK = 'ATHLETE_OF_THE_WEEK',
  NATIONAL = 'NATIONAL',
  PHOTOS = 'PHOTOS',
  PODCAST = 'PODCAST',
  RECRUITING = 'RECRUITING',
  TOP_PLAYERS = 'TOP_PLAYERS',
  VIDEOS = 'VIDEOS',
  VIDEO_HIGHLIGHTS = 'VIDEO_HIGHLIGHTS'
}

export enum ContentTypeEnum {
  PHOTO_GALLERY = 'PHOTO_GALLERY',
  POLL = 'POLL',
  TEMPEST_ARTICLE = 'TEMPEST_ARTICLE',
  TWEET = 'TWEET',
  VOLTAX_ARTICLE = 'VOLTAX_ARTICLE',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO'
}

export enum ContentTypeTagEnum {
  RANKINGS = 'RANKINGS'
}

export enum ContestAffiliateEnum {
  GOFAN_TICKETING = 'GOFAN_TICKETING',
  HOMETOWN_TICKETING = 'HOMETOWN_TICKETING',
  NFHS_NETWORK = 'NFHS_NETWORK'
}

export enum ContestExpectedStatusEnum {
  COMPLETED = 'COMPLETED',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING'
}

export enum ContestOrderEnum {
  AWAY_TEAM_NAME = 'AWAY_TEAM_NAME',
  CONTEST_TYPE = 'CONTEST_TYPE',
  DATE = 'DATE',
  /** @deprecated Use `DATE` and `orderDirection``: `ASC` */
  DATE_ASC = 'DATE_ASC',
  /** @deprecated Use `DATE` and `orderDirection``: `DESC` */
  DATE_DESC = 'DATE_DESC',
  HOME_TEAM_NAME = 'HOME_TEAM_NAME',
  SCOREBOARD = 'SCOREBOARD',
  STATUS = 'STATUS'
}

export type ContestParticipantInputObject = {
  forfeited?: InputMaybe<Scalars['Boolean']['input']>;
  linescore?: InputMaybe<Array<Scalars['Int']['input']>>;
  location: ContestTeamLocationEnum;
  participantId?: InputMaybe<Scalars['String']['input']>;
  participantType: ContestTeamTypeEnum;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContestStatusEnum {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  DELAYED = 'DELAYED',
  FORFEIT = 'FORFEIT',
  LIVE = 'LIVE',
  POSTPONED = 'POSTPONED',
  UPCOMING = 'UPCOMING'
}

export enum ContestTeamLocationEnum {
  AWAY = 'AWAY',
  HOME = 'HOME',
  NEUTRAL = 'NEUTRAL'
}

export enum ContestTeamResultEnum {
  FORFEIT = 'FORFEIT',
  LOSS = 'LOSS',
  TIE = 'TIE',
  WIN = 'WIN'
}

export enum ContestTeamTypeEnum {
  BYE = 'BYE',
  TBD = 'TBD',
  TEAM = 'TEAM',
  UNKNOWN = 'UNKNOWN'
}

export enum ContestTypeEnum {
  LEAGUE = 'LEAGUE',
  NON_LEAGUE = 'NON_LEAGUE',
  PLAYOFFS = 'PLAYOFFS',
  SCRIMMAGE = 'SCRIMMAGE',
  TOURNAMENT = 'TOURNAMENT'
}

/** The different types of devices supported. */
export enum DeviceTypeEnum {
  /** APNS Devices. */
  APNS = 'APNS',
  /** Expo Devices */
  EXPO = 'EXPO',
  /** FCM Devices. */
  FCM = 'FCM'
}

export enum EntityTypeEnum {
  Bracket = 'Bracket',
  Group = 'Group',
  Player = 'Player',
  School = 'School',
  Team = 'Team'
}

export type ExpoDeviceTokenInputType = {
  /** The actual token. */
  data: Scalars['String']['input'];
  /** Analagous to the device ID. */
  installation_id: Scalars['String']['input'];
};

export type FollowableEntity = {
  id: Scalars['ID']['input'];
  index?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  type: FollowableEntityType;
};

export enum FollowableEntityType {
  /** A group of teams */
  Group = 'Group',
  /** A team */
  Team = 'Team'
}

export type GamePlayerInputType = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jersey_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stat?: InputMaybe<StatInputType>;
};

/** Properties of creating and updating game player stats */
export type GamePlayerStatsInputType = {
  away?: InputMaybe<GameTeamInputType>;
  home?: InputMaybe<GameTeamInputType>;
  id: Scalars['ID']['input'];
};

export enum GameTeamEntityType {
  SCHOOL = 'SCHOOL',
  /**
   * A school
   * @deprecated Use `SCHOOL` instead.
   */
  School = 'School',
  TEAM = 'TEAM',
  /**
   * A team
   * @deprecated Use `TEAM` instead.
   */
  Team = 'Team'
}

export type GameTeamInputType = {
  game_players?: InputMaybe<Array<GamePlayerInputType>>;
};

export enum GameTeamResultEnum {
  LOSS = 'LOSS',
  TIE = 'TIE',
  WIN = 'WIN'
}

export enum GenderEnum {
  CO_ED = 'CO_ED',
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export enum GenderSportEnum {
  BADMINTON = 'BADMINTON',
  BASEBALL = 'BASEBALL',
  BEACH_VOLLEYBALL = 'BEACH_VOLLEYBALL',
  BOYS_BASKETBALL = 'BOYS_BASKETBALL',
  BOYS_CROSS_COUNTRY = 'BOYS_CROSS_COUNTRY',
  BOYS_FENCING = 'BOYS_FENCING',
  BOYS_FIELD_HOCKEY = 'BOYS_FIELD_HOCKEY',
  BOYS_GOLF = 'BOYS_GOLF',
  BOYS_ICE_HOCKEY = 'BOYS_ICE_HOCKEY',
  BOYS_LACROSSE = 'BOYS_LACROSSE',
  BOYS_SOCCER = 'BOYS_SOCCER',
  BOYS_TENNIS = 'BOYS_TENNIS',
  BOYS_ULTIMATE_FRISBEE = 'BOYS_ULTIMATE_FRISBEE',
  BOYS_VOLLEYBALL = 'BOYS_VOLLEYBALL',
  BOYS_WATER_POLO = 'BOYS_WATER_POLO',
  FIELD_HOCKEY = 'FIELD_HOCKEY',
  FLAG_FOOTBALL = 'FLAG_FOOTBALL',
  FOOTBALL = 'FOOTBALL',
  GIRLS_BASKETBALL = 'GIRLS_BASKETBALL',
  GIRLS_CROSS_COUNTRY = 'GIRLS_CROSS_COUNTRY',
  GIRLS_FENCING = 'GIRLS_FENCING',
  GIRLS_GOLF = 'GIRLS_GOLF',
  GIRLS_ICE_HOCKEY = 'GIRLS_ICE_HOCKEY',
  GIRLS_LACROSSE = 'GIRLS_LACROSSE',
  GIRLS_SOCCER = 'GIRLS_SOCCER',
  GIRLS_TENNIS = 'GIRLS_TENNIS',
  GIRLS_ULTIMATE_FRISBEE = 'GIRLS_ULTIMATE_FRISBEE',
  GIRLS_VOLLEYBALL = 'GIRLS_VOLLEYBALL',
  GIRLS_WATER_POLO = 'GIRLS_WATER_POLO',
  GIRLS_WRESTLING = 'GIRLS_WRESTLING',
  SOFTBALL = 'SOFTBALL',
  UNIFIED_BASKETBALL = 'UNIFIED_BASKETBALL',
  WRESTLING = 'WRESTLING'
}

export enum LevelEnum {
  ADULT = 'ADULT',
  C_TEAM = 'C_TEAM',
  EIGHTH_GRADE = 'EIGHTH_GRADE',
  FIBA = 'FIBA',
  FIFTHTEENU = 'FIFTHTEENU',
  FIFTH_GRADE = 'FIFTH_GRADE',
  FOURTEENU = 'FOURTEENU',
  FOURTH_GRADE = 'FOURTH_GRADE',
  FRESHMAN = 'FRESHMAN',
  JUNIOR_COLLEGE = 'JUNIOR_COLLEGE',
  JUNIOR_VARSITY = 'JUNIOR_VARSITY',
  NAIA = 'NAIA',
  NCAA_D1 = 'NCAA_D1',
  NCAA_D2 = 'NCAA_D2',
  NCAA_D3 = 'NCAA_D3',
  PROFESSIONAL = 'PROFESSIONAL',
  RECREATIONAL = 'RECREATIONAL',
  SEMI_PROFESSIONAL = 'SEMI_PROFESSIONAL',
  SEVENTEENU = 'SEVENTEENU',
  SEVENTH_GRADE = 'SEVENTH_GRADE',
  SIXTEENU = 'SIXTEENU',
  SIXTH_GRADE = 'SIXTH_GRADE',
  VARSITY = 'VARSITY',
  YOUTH = 'YOUTH'
}

export enum NfhsNetworkUrlTypeEnum {
  DIRECT = 'DIRECT',
  STATE = 'STATE'
}

export type NotifiableEntity = {
  id: Scalars['ID']['input'];
  type: NotifiableEntityType;
};

export enum NotifiableEntityType {
  /** A game */
  Game = 'Game',
  /** A team */
  Team = 'Team'
}

export type NotifiableGroupEntity = {
  ids: Array<Scalars['ID']['input']>;
  type: NotifiableEntityType;
};

/** The different types of notifications supported. */
export enum NotificationTypeKeyEnum {
  /** Notifications about a game's final score. */
  FINAL_SCORE = 'FINAL_SCORE',
  /** Notifications about a game starting. */
  GAME_START = 'GAME_START',
  /** Notifications about a game entering halftime. */
  HALFTIME = 'HALFTIME',
  /** Notifications used to help remind users to enter a score. */
  MISSING_SCORE = 'MISSING_SCORE',
  /** Notifications about the news around a team. */
  NEWS = 'NEWS',
  /** Notifications for when a team recieves a new follower. */
  NEW_FOLLOWER = 'NEW_FOLLOWER',
  /** Notifications about a Pick Em contest ending. */
  PICK_EM_CONTEST_END = 'PICK_EM_CONTEST_END',
  /** Notifications about a Pick Em contest with an impending round */
  PICK_EM_CONTEST_INCOMPLETE_PICKS = 'PICK_EM_CONTEST_INCOMPLETE_PICKS',
  /** Notifications about a Pick Em contest round summary. */
  PICK_EM_CONTEST_ROUND_SUMMARY = 'PICK_EM_CONTEST_ROUND_SUMMARY',
  /** Notifications about a Pick Em contest starting. */
  PICK_EM_CONTEST_START = 'PICK_EM_CONTEST_START',
  /** Notifications prior to a game's start */
  PREGAME = 'PREGAME',
  /** Notifications about new rankings. */
  RANKING = 'RANKING',
  /** Notifications about an upcoming game. */
  UPCOMING_GAME = 'UPCOMING_GAME'
}

export enum OrderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum OrganizationOrderEnum {
  FULL_NAME = 'FULL_NAME',
  NAME = 'NAME',
  SORTING_PRIORITY = 'SORTING_PRIORITY'
}

export enum OrganizationTypeEnum {
  ASSOCIATION = 'ASSOCIATION',
  DIVISION = 'DIVISION',
  LEAGUE = 'LEAGUE',
  METRO = 'METRO',
  SECTION = 'SECTION'
}

/** Properties of creating and updating a Pick Em */
export type PickEmInputObject = {
  gameTeamId: Scalars['ID']['input'];
};

export enum PickEmResultTypeEnum {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT'
}

export enum RankingFormulaEnum {
  CIF_RPI = 'CIF_RPI',
  COLLEY = 'COLLEY',
  COLLEY_ROYALTY = 'COLLEY_ROYALTY',
  DIVISION_POINT_INDEX = 'DIVISION_POINT_INDEX',
  MIAA_POWER_RATING = 'MIAA_POWER_RATING',
  OSAA_RPI = 'OSAA_RPI',
  OSAA_RPI_COLLEY = 'OSAA_RPI_COLLEY',
  POINT_INDEX = 'POINT_INDEX',
  POWER_25 = 'POWER_25',
  QUALITY_POINT_RATING = 'QUALITY_POINT_RATING'
}

export type RosterAthleteInputObject = {
  birthdate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  classYear?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  firstName: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Int']['input']>;
  jerseyNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  lastName: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
  positions?: InputMaybe<Array<Scalars['String']['input']>>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export enum RostersOrderEnum {
  DATE = 'DATE'
}

/** Properties of creating and updating a school */
export type SchoolInputType = {
  abbrev?: InputMaybe<Scalars['String']['input']>;
  athletic_director?: InputMaybe<Scalars['String']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  enrollment_count?: InputMaybe<Scalars['Int']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  primary_color?: InputMaybe<Scalars['String']['input']>;
  principal?: InputMaybe<Scalars['String']['input']>;
  secondary_color?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export enum SchoolTypeEnum {
  PRIVATE_SCHOOL = 'PRIVATE_SCHOOL',
  PUBLIC_SCHOOL = 'PUBLIC_SCHOOL'
}

export enum SchoolsOrderEnum {
  NAME = 'NAME',
  OWNED = 'OWNED'
}

export enum ScoreboardDateScopeEnum {
  MONTH = 'MONTH'
}

export enum SearchableEntityTypeEnum {
  BRACKET = 'BRACKET',
  ORGANIZATION = 'ORGANIZATION',
  SCHOOL = 'SCHOOL',
  TEAM = 'TEAM'
}

export enum SponsorPlacementEnum {
  AL_AHSAA_WHITE_LABEL = 'AL_AHSAA_WHITE_LABEL',
  AR_AAA_WHITE_LABEL = 'AR_AAA_WHITE_LABEL',
  CA_CIF_CENTRAL_WHITE_LABEL = 'CA_CIF_CENTRAL_WHITE_LABEL',
  CA_CIF_LA_CITY_WHITE_LABEL = 'CA_CIF_LA_CITY_WHITE_LABEL',
  CA_CIF_NCS_WHITE_LABEL = 'CA_CIF_NCS_WHITE_LABEL',
  CA_CIF_SDS_WHITE_LABEL = 'CA_CIF_SDS_WHITE_LABEL',
  CA_CIF_SF_WHITE_LABEL = 'CA_CIF_SF_WHITE_LABEL',
  CA_CIF_SS_WHITE_LABEL = 'CA_CIF_SS_WHITE_LABEL',
  FAN_APP_FEED_BOTTOM = 'FAN_APP_FEED_BOTTOM',
  FAN_APP_HOME_BOTTOM = 'FAN_APP_HOME_BOTTOM',
  FAN_APP_SPLASH_MODAL = 'FAN_APP_SPLASH_MODAL',
  MS_MHSAA_WHITE_LABEL = 'MS_MHSAA_WHITE_LABEL',
  SBLIVE_WHITE_LABEL = 'SBLIVE_WHITE_LABEL',
  VT_VPA_WHITE_LABEL = 'VT_VPA_WHITE_LABEL',
  WA_WIAA_WHITE_LABEL = 'WA_WIAA_WHITE_LABEL'
}

export enum SportEnum {
  BADMINTON = 'BADMINTON',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  BEACH_VOLLEYBALL = 'BEACH_VOLLEYBALL',
  CROSS_COUNTRY = 'CROSS_COUNTRY',
  FENCING = 'FENCING',
  FIELD_HOCKEY = 'FIELD_HOCKEY',
  FLAG_FOOTBALL = 'FLAG_FOOTBALL',
  FOOTBALL = 'FOOTBALL',
  GOLF = 'GOLF',
  ICE_HOCKEY = 'ICE_HOCKEY',
  LACROSSE = 'LACROSSE',
  SOCCER = 'SOCCER',
  SOFTBALL = 'SOFTBALL',
  TENNIS = 'TENNIS',
  ULTIMATE_FRISBEE = 'ULTIMATE_FRISBEE',
  UNIFIED_BASKETBALL = 'UNIFIED_BASKETBALL',
  VOLLEYBALL = 'VOLLEYBALL',
  WATER_POLO = 'WATER_POLO',
  WRESTLING = 'WRESTLING'
}

export type StatInputType = {
  assisted_tackles?: InputMaybe<Scalars['Int']['input']>;
  assists?: InputMaybe<Scalars['Int']['input']>;
  at_bats?: InputMaybe<Scalars['Int']['input']>;
  attack_errors?: InputMaybe<Scalars['Int']['input']>;
  average_finish?: InputMaybe<Scalars['Int']['input']>;
  ball_handling_assists?: InputMaybe<Scalars['Int']['input']>;
  ball_handling_attempts?: InputMaybe<Scalars['Int']['input']>;
  ball_handling_errors?: InputMaybe<Scalars['Int']['input']>;
  batters_faced?: InputMaybe<Scalars['Int']['input']>;
  best_finish?: InputMaybe<Scalars['Int']['input']>;
  birdies?: InputMaybe<Scalars['Int']['input']>;
  block_assists?: InputMaybe<Scalars['Int']['input']>;
  blocking_errors?: InputMaybe<Scalars['Int']['input']>;
  blocks?: InputMaybe<Scalars['Int']['input']>;
  bogeys?: InputMaybe<Scalars['Int']['input']>;
  break_point_attempts?: InputMaybe<Scalars['Int']['input']>;
  break_points_won?: InputMaybe<Scalars['Int']['input']>;
  caught_stealing?: InputMaybe<Scalars['Int']['input']>;
  cautions?: InputMaybe<Scalars['Int']['input']>;
  clears?: InputMaybe<Scalars['Int']['input']>;
  complete_game?: InputMaybe<Scalars['Int']['input']>;
  concussion?: InputMaybe<Scalars['Int']['input']>;
  concussions?: InputMaybe<Scalars['Int']['input']>;
  defensive_blocked_field_goals?: InputMaybe<Scalars['Int']['input']>;
  defensive_blocked_punts?: InputMaybe<Scalars['Int']['input']>;
  defensive_fumble_recovery_yard?: InputMaybe<Scalars['Int']['input']>;
  defensive_fumbles_forced?: InputMaybe<Scalars['Int']['input']>;
  defensive_fumbles_recovered?: InputMaybe<Scalars['Int']['input']>;
  defensive_interception_yards?: InputMaybe<Scalars['Int']['input']>;
  defensive_interceptions?: InputMaybe<Scalars['Int']['input']>;
  defensive_passes_defended?: InputMaybe<Scalars['Int']['input']>;
  defensive_rebounds?: InputMaybe<Scalars['Int']['input']>;
  defensive_sacks?: InputMaybe<Scalars['Int']['input']>;
  defensive_save?: InputMaybe<Scalars['Int']['input']>;
  defensive_touchdown?: InputMaybe<Scalars['Int']['input']>;
  dig_errors?: InputMaybe<Scalars['Int']['input']>;
  digs?: InputMaybe<Scalars['Int']['input']>;
  double_faults?: InputMaybe<Scalars['Int']['input']>;
  double_plays?: InputMaybe<Scalars['Int']['input']>;
  doubles?: InputMaybe<Scalars['Int']['input']>;
  doubles_allowed?: InputMaybe<Scalars['Int']['input']>;
  draw_control?: InputMaybe<Scalars['Int']['input']>;
  draws?: InputMaybe<Scalars['Int']['input']>;
  eagles?: InputMaybe<Scalars['Int']['input']>;
  earned_runs_allowed?: InputMaybe<Scalars['Int']['input']>;
  ejections?: InputMaybe<Scalars['Int']['input']>;
  ejects_drawn?: InputMaybe<Scalars['Int']['input']>;
  ejects_given?: InputMaybe<Scalars['Int']['input']>;
  errors_stat?: InputMaybe<Scalars['Int']['input']>;
  events_played?: InputMaybe<Scalars['Int']['input']>;
  faceoffs_lost?: InputMaybe<Scalars['Int']['input']>;
  faceoffs_won?: InputMaybe<Scalars['Int']['input']>;
  fast_break?: InputMaybe<Scalars['Int']['input']>;
  field_goals_attempts?: InputMaybe<Scalars['Int']['input']>;
  field_goals_longest?: InputMaybe<Scalars['Int']['input']>;
  field_goals_made?: InputMaybe<Scalars['Int']['input']>;
  fielders_choice?: InputMaybe<Scalars['Int']['input']>;
  fielding_errors?: InputMaybe<Scalars['Int']['input']>;
  finish?: InputMaybe<Scalars['Int']['input']>;
  fouls?: InputMaybe<Scalars['Int']['input']>;
  free_throws_attempted?: InputMaybe<Scalars['Int']['input']>;
  free_throws_made?: InputMaybe<Scalars['Int']['input']>;
  fumbles_lost?: InputMaybe<Scalars['Int']['input']>;
  fumbles_recovered?: InputMaybe<Scalars['Int']['input']>;
  games_played?: InputMaybe<Scalars['Int']['input']>;
  games_started?: InputMaybe<Scalars['Int']['input']>;
  goalie_saves?: InputMaybe<Scalars['Int']['input']>;
  goals?: InputMaybe<Scalars['Int']['input']>;
  goals_against?: InputMaybe<Scalars['Int']['input']>;
  ground_balls?: InputMaybe<Scalars['Int']['input']>;
  hit_batter?: InputMaybe<Scalars['Int']['input']>;
  hit_by_pitch?: InputMaybe<Scalars['Int']['input']>;
  hits_allowed?: InputMaybe<Scalars['Int']['input']>;
  holds?: InputMaybe<Scalars['Int']['input']>;
  home_runs?: InputMaybe<Scalars['Int']['input']>;
  home_runs_allowed?: InputMaybe<Scalars['Int']['input']>;
  hurries?: InputMaybe<Scalars['Int']['input']>;
  innings_pitched?: InputMaybe<Scalars['String']['input']>;
  innings_played?: InputMaybe<Scalars['Int']['input']>;
  intentional_walks?: InputMaybe<Scalars['Int']['input']>;
  kick_return_long?: InputMaybe<Scalars['Int']['input']>;
  kick_return_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  kickoff_attempts?: InputMaybe<Scalars['Int']['input']>;
  kickoff_return_yards?: InputMaybe<Scalars['Int']['input']>;
  kickoff_yards?: InputMaybe<Scalars['Int']['input']>;
  kickoffs?: InputMaybe<Scalars['Int']['input']>;
  kickoffs_long?: InputMaybe<Scalars['Int']['input']>;
  kills?: InputMaybe<Scalars['Int']['input']>;
  losses?: InputMaybe<Scalars['Int']['input']>;
  minutes_in_goal?: InputMaybe<Scalars['Int']['input']>;
  minutes_on_field?: InputMaybe<Scalars['Int']['input']>;
  minutes_played?: InputMaybe<Scalars['Int']['input']>;
  offensive_rebounds?: InputMaybe<Scalars['Int']['input']>;
  overtime_losses?: InputMaybe<Scalars['Int']['input']>;
  pars?: InputMaybe<Scalars['Int']['input']>;
  passed_balls?: InputMaybe<Scalars['Int']['input']>;
  passing_attempts?: InputMaybe<Scalars['Int']['input']>;
  passing_completions?: InputMaybe<Scalars['Int']['input']>;
  passing_interceptions?: InputMaybe<Scalars['Int']['input']>;
  passing_sacks?: InputMaybe<Scalars['Int']['input']>;
  passing_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  passing_yards?: InputMaybe<Scalars['Int']['input']>;
  pat_attempts?: InputMaybe<Scalars['Int']['input']>;
  pat_made?: InputMaybe<Scalars['Int']['input']>;
  penalties?: InputMaybe<Scalars['Int']['input']>;
  penalties_in_minutes?: InputMaybe<Scalars['Int']['input']>;
  penalty_corners?: InputMaybe<Scalars['Int']['input']>;
  penalty_kick_attempts?: InputMaybe<Scalars['Int']['input']>;
  penalty_kick_goals?: InputMaybe<Scalars['Int']['input']>;
  periods_played?: InputMaybe<Scalars['Int']['input']>;
  personal_fouls?: InputMaybe<Scalars['Int']['input']>;
  pickoffs?: InputMaybe<Scalars['Int']['input']>;
  pitch_count?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  power_play_assists?: InputMaybe<Scalars['Int']['input']>;
  power_play_goals?: InputMaybe<Scalars['Int']['input']>;
  punt_attempts?: InputMaybe<Scalars['Int']['input']>;
  punt_long?: InputMaybe<Scalars['Int']['input']>;
  punt_return_attempts?: InputMaybe<Scalars['Int']['input']>;
  punt_return_long?: InputMaybe<Scalars['Int']['input']>;
  punt_return_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  punt_return_yards?: InputMaybe<Scalars['Int']['input']>;
  punt_yards?: InputMaybe<Scalars['Int']['input']>;
  punts?: InputMaybe<Scalars['Int']['input']>;
  punts_inside_20_yard_line?: InputMaybe<Scalars['Int']['input']>;
  put_outs?: InputMaybe<Scalars['Int']['input']>;
  qb_hits?: InputMaybe<Scalars['Int']['input']>;
  quarters_played?: InputMaybe<Scalars['Int']['input']>;
  reached_on_error?: InputMaybe<Scalars['Int']['input']>;
  receiving_errors?: InputMaybe<Scalars['Int']['input']>;
  receiving_fumbles?: InputMaybe<Scalars['Int']['input']>;
  receiving_longest?: InputMaybe<Scalars['Int']['input']>;
  receiving_receptions?: InputMaybe<Scalars['Int']['input']>;
  receiving_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  receiving_yards?: InputMaybe<Scalars['Int']['input']>;
  receptions?: InputMaybe<Scalars['Int']['input']>;
  red_cards?: InputMaybe<Scalars['Int']['input']>;
  return_points_won?: InputMaybe<Scalars['Int']['input']>;
  returns_attempted?: InputMaybe<Scalars['Int']['input']>;
  rounds_played?: InputMaybe<Scalars['Int']['input']>;
  runners_caught_stealing?: InputMaybe<Scalars['Int']['input']>;
  runners_left_in_scoring_position?: InputMaybe<Scalars['Int']['input']>;
  runners_left_on_base?: InputMaybe<Scalars['Int']['input']>;
  runs?: InputMaybe<Scalars['Int']['input']>;
  runs_allowed?: InputMaybe<Scalars['Int']['input']>;
  runs_batted_in?: InputMaybe<Scalars['Int']['input']>;
  rushing_attempts?: InputMaybe<Scalars['Int']['input']>;
  rushing_fumbles?: InputMaybe<Scalars['Int']['input']>;
  rushing_longest?: InputMaybe<Scalars['Int']['input']>;
  rushing_touchdowns?: InputMaybe<Scalars['Int']['input']>;
  rushing_yards?: InputMaybe<Scalars['Int']['input']>;
  sacrifice_bunts?: InputMaybe<Scalars['Int']['input']>;
  sacrifice_flies?: InputMaybe<Scalars['Int']['input']>;
  saves?: InputMaybe<Scalars['Int']['input']>;
  service_aces?: InputMaybe<Scalars['Int']['input']>;
  service_attempts?: InputMaybe<Scalars['Int']['input']>;
  service_errors?: InputMaybe<Scalars['Int']['input']>;
  service_faults?: InputMaybe<Scalars['Int']['input']>;
  service_points_won?: InputMaybe<Scalars['Int']['input']>;
  sets_played?: InputMaybe<Scalars['Int']['input']>;
  shootout_saves?: InputMaybe<Scalars['Int']['input']>;
  shootout_shorts_against?: InputMaybe<Scalars['Int']['input']>;
  short_hand_assists?: InputMaybe<Scalars['Int']['input']>;
  short_hand_goals?: InputMaybe<Scalars['Int']['input']>;
  shots?: InputMaybe<Scalars['Int']['input']>;
  shots_against?: InputMaybe<Scalars['Int']['input']>;
  shots_on_goal?: InputMaybe<Scalars['Int']['input']>;
  singles?: InputMaybe<Scalars['Int']['input']>;
  singles_allowed?: InputMaybe<Scalars['Int']['input']>;
  snaps_played?: InputMaybe<Scalars['Int']['input']>;
  solo_blocks?: InputMaybe<Scalars['Int']['input']>;
  solo_tackles?: InputMaybe<Scalars['Int']['input']>;
  sprints_attempted?: InputMaybe<Scalars['Int']['input']>;
  sprints_won?: InputMaybe<Scalars['Int']['input']>;
  steals?: InputMaybe<Scalars['Int']['input']>;
  stolen_base_attempts?: InputMaybe<Scalars['Int']['input']>;
  stolen_bases?: InputMaybe<Scalars['Int']['input']>;
  strikeouts_def?: InputMaybe<Scalars['Int']['input']>;
  strikeouts_off?: InputMaybe<Scalars['Int']['input']>;
  strikes_thrown?: InputMaybe<Scalars['Int']['input']>;
  tackles_for_loss?: InputMaybe<Scalars['Int']['input']>;
  take_away?: InputMaybe<Scalars['Int']['input']>;
  targets?: InputMaybe<Scalars['Int']['input']>;
  technical_fouls?: InputMaybe<Scalars['Int']['input']>;
  three_points_attempted?: InputMaybe<Scalars['Int']['input']>;
  three_points_made?: InputMaybe<Scalars['Int']['input']>;
  ties?: InputMaybe<Scalars['Int']['input']>;
  time_on_ice?: InputMaybe<Scalars['Int']['input']>;
  top_five_finishes?: InputMaybe<Scalars['Int']['input']>;
  top_ten_finishes?: InputMaybe<Scalars['Int']['input']>;
  top_twenty_finishes?: InputMaybe<Scalars['Int']['input']>;
  total_attack_attempts?: InputMaybe<Scalars['Int']['input']>;
  total_faceoffs?: InputMaybe<Scalars['Int']['input']>;
  total_kick_returns?: InputMaybe<Scalars['Int']['input']>;
  total_punt_returns?: InputMaybe<Scalars['Int']['input']>;
  total_strokes?: InputMaybe<Scalars['Int']['input']>;
  touchbacks?: InputMaybe<Scalars['Int']['input']>;
  triples?: InputMaybe<Scalars['Int']['input']>;
  triples_allowed?: InputMaybe<Scalars['Int']['input']>;
  turnovers?: InputMaybe<Scalars['Int']['input']>;
  two_point_conversions?: InputMaybe<Scalars['Int']['input']>;
  two_points_attempted?: InputMaybe<Scalars['Int']['input']>;
  two_points_made?: InputMaybe<Scalars['Int']['input']>;
  vs_par?: InputMaybe<Scalars['Int']['input']>;
  walks?: InputMaybe<Scalars['Int']['input']>;
  walks_allowed?: InputMaybe<Scalars['Int']['input']>;
  wins?: InputMaybe<Scalars['Int']['input']>;
  yards_lost?: InputMaybe<Scalars['Int']['input']>;
  yellow_cards?: InputMaybe<Scalars['Int']['input']>;
};

export enum StateEnum {
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  ALBERTA = 'ALBERTA',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  BRITISH_COLUMBIA = 'BRITISH_COLUMBIA',
  CALIFORNIA = 'CALIFORNIA',
  COLORADO = 'COLORADO',
  CONNECTICUT = 'CONNECTICUT',
  DELAWARE = 'DELAWARE',
  DISTRICT_OF_COLUMBIA = 'DISTRICT_OF_COLUMBIA',
  FLORIDA = 'FLORIDA',
  GEORGIA = 'GEORGIA',
  HAWAII = 'HAWAII',
  IDAHO = 'IDAHO',
  ILLINOIS = 'ILLINOIS',
  INDIANA = 'INDIANA',
  IOWA = 'IOWA',
  KANSAS = 'KANSAS',
  KENTUCKY = 'KENTUCKY',
  LOUISIANA = 'LOUISIANA',
  MAINE = 'MAINE',
  MANITOBA = 'MANITOBA',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MONTANA = 'MONTANA',
  NEBRASKA = 'NEBRASKA',
  NEVADA = 'NEVADA',
  NEWFOUNDLAND_AND_LABRADOR = 'NEWFOUNDLAND_AND_LABRADOR',
  NEW_BRUNSWICK = 'NEW_BRUNSWICK',
  NEW_HAMPSHIRE = 'NEW_HAMPSHIRE',
  NEW_JERSEY = 'NEW_JERSEY',
  NEW_MEXICO = 'NEW_MEXICO',
  NEW_YORK = 'NEW_YORK',
  NORTH_CAROLINA = 'NORTH_CAROLINA',
  NORTH_DAKOTA = 'NORTH_DAKOTA',
  NOVA_SCOTIA = 'NOVA_SCOTIA',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  ONTARIO = 'ONTARIO',
  OREGON = 'OREGON',
  PENNSYLVANIA = 'PENNSYLVANIA',
  PRINCE_EDWARD_ISLAND = 'PRINCE_EDWARD_ISLAND',
  PUERTO_RICO = 'PUERTO_RICO',
  QUEBEC = 'QUEBEC',
  RHODE_ISLAND = 'RHODE_ISLAND',
  SASKATCHEWAN = 'SASKATCHEWAN',
  SOUTH_CAROLINA = 'SOUTH_CAROLINA',
  SOUTH_DAKOTA = 'SOUTH_DAKOTA',
  TENNESSEE = 'TENNESSEE',
  TEXAS = 'TEXAS',
  UTAH = 'UTAH',
  VERMONT = 'VERMONT',
  VIRGINIA = 'VIRGINIA',
  WASHINGTON = 'WASHINGTON',
  WEST_VIRGINIA = 'WEST_VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING'
}

export enum StatsOrderEnum {
  POINTS = 'POINTS',
  YARDS = 'YARDS'
}

/** Properties of creating and updating a team coach */
export type TeamCoachInputType = {
  coach_type_id?: InputMaybe<Scalars['Int']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  year_hired?: InputMaybe<Scalars['Int']['input']>;
};

/** Properties of creating and updating a game */
export type TeamGameInputType = {
  date: Scalars['String']['input'];
  game_type_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_home: Scalars['Boolean']['input'];
  is_neutral: Scalars['Boolean']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  opponent_id: Scalars['ID']['input'];
  rule_type_id?: InputMaybe<Scalars['Int']['input']>;
  secondary_status_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
  track_subs?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Properties of creating and updating a game */
export type TeamGameResultsInputType = {
  featured_forfeit?: InputMaybe<Scalars['Boolean']['input']>;
  featured_linescore?: InputMaybe<Array<Scalars['Int']['input']>>;
  featured_score: Scalars['Int']['input'];
  featured_shootout_score?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  opponent_forfeit?: InputMaybe<Scalars['Boolean']['input']>;
  opponent_linescore?: InputMaybe<Array<Scalars['Int']['input']>>;
  opponent_score: Scalars['Int']['input'];
  opponent_shootout_score?: InputMaybe<Scalars['Int']['input']>;
  periods?: InputMaybe<Scalars['Int']['input']>;
};

/** Properties of creating and updating a team */
export type TeamInputType = {
  abbrev?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  genderSport?: InputMaybe<GenderSportEnum>;
  hashtag?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LevelEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_color?: InputMaybe<Scalars['String']['input']>;
  rivalId?: InputMaybe<Scalars['ID']['input']>;
  school_id?: InputMaybe<Scalars['Int']['input']>;
  secondary_color?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  teamOrganizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<Scalars['String']['input']>;
  venueCapacity?: InputMaybe<Scalars['Int']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** Properties of creating and updating a team player */
export type TeamPlayerInputType = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  class_year?: InputMaybe<Scalars['Int']['input']>;
  cover_image?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  jersey_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  position_2_id?: InputMaybe<Scalars['Int']['input']>;
  position_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export enum TeamsOrderEnum {
  NAME = 'NAME',
  OWNED = 'OWNED'
}

/** Properties of an entity that a user is invited to manager */
export type UserEntityInvitationEntityInputType = {
  entity_id: Scalars['Int']['input'];
  entity_type: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export enum UserEntityRoleEnum {
  FAVORITE = 'FAVORITE',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
  RIVAL = 'RIVAL',
  SCOREKEEPER = 'SCOREKEEPER'
}

export enum UserEntityRoleLegacyEnum {
  Favorite = 'Favorite',
  Manager = 'Manager',
  Owner = 'Owner',
  Rival = 'Rival',
  Scorekeeper = 'Scorekeeper'
}

export enum UserEntityStatusEnum {
  CONFIRMED = 'CONFIRMED',
  REQUESTED = 'REQUESTED',
  TENTATIVE = 'TENTATIVE'
}

export enum UserEntityTypeEnum {
  BRACKET = 'BRACKET',
  GROUP = 'GROUP',
  SCHOOL = 'SCHOOL',
  TEAM = 'TEAM'
}

export enum UserNotificationStatusEnum {
  DISMISSED = 'DISMISSED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SEEN = 'SEEN',
  SENT = 'SENT'
}

export type AcceptUserEntityInvitationMutationVariables = Exact<{
  inviteToken: Scalars['String']['input'];
}>;

export type AcceptUserEntityInvitationMutation = {
  acceptUserEntityInvitation: {
    invitationStatus: string;
    entities: Array<{
      entity:
        | { __typename: 'Bracket' }
        | { __typename: 'Group' }
        | { __typename: 'School'; id: string }
        | { __typename: 'Team'; id: string };
    }>;
  };
};

export type AddBracketGamesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  roundId: BracketRoundIdEnum;
  bracketContests: Array<BracketContestInputObject> | BracketContestInputObject;
}>;

export type AddBracketGamesMutation = {
  addBracketGames: {
    playInGames: number;
    rounds:
      | Array<{
          defaultName: string;
          endDate: string | undefined;
          id: BracketRoundIdEnum;
          isLoserRound: boolean;
          isNeutral: boolean;
          name: string | undefined;
          size: number;
          startDate: string | undefined;
          bracketContests: Array<{
            date: string | undefined;
            name: string | undefined;
            gameNumber: number;
            isNeutral: boolean;
            location: string | undefined;
            position: number;
            roundId: BracketRoundIdEnum;
            secondaryStatusId: number | undefined;
            statusId: number | undefined;
            away: {
              isBye: boolean;
              isTbd: boolean;
              isLoser: boolean;
              isOriginal: boolean;
              lineScore: Array<number> | undefined;
              shootoutScore: number | undefined;
              seed: string | undefined;
              score: number | undefined;
              team:
                | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
                | undefined;
              seriesTeamInfo:
                | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
                | undefined;
            };
            home: {
              isBye: boolean;
              isTbd: boolean;
              isLoser: boolean;
              isOriginal: boolean;
              lineScore: Array<number> | undefined;
              shootoutScore: number | undefined;
              seed: string | undefined;
              score: number | undefined;
              team:
                | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
                | undefined;
              seriesTeamInfo:
                | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
                | undefined;
            };
            seriesContests:
              | Array<{ date: string | undefined; statusId: number | undefined; secondaryStatusId: number | undefined }>
              | undefined;
          }>;
        }>
      | undefined;
  };
};

export type AdminBracketClassificationQueryVariables = Exact<{
  genderId?: InputMaybe<Scalars['Int']['input']>;
  sportId?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminBracketClassificationQuery = {
  bracketClassifications: Array<{ id: string; name: string; secondaryClassificationType: string | undefined; type: string }>;
};

export type AdminBracketDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminBracketDeleteMutation = { deleteBracket: { id: string } };

export type AdminBracketDetailsMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  autoAdvance?: InputMaybe<Scalars['Boolean']['input']>;
  syncScores?: InputMaybe<Scalars['Boolean']['input']>;
  coverPhotoImage?: InputMaybe<Scalars['String']['input']>;
  formatId: Scalars['Int']['input'];
  genderSportSlug: Scalars['String']['input'];
  hideSeedNumbers?: InputMaybe<Scalars['Boolean']['input']>;
  logoImage?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfTeams: Scalars['Int']['input'];
  showHomeTeamFirst?: InputMaybe<Scalars['Boolean']['input']>;
  showThirdPlaceGame?: InputMaybe<Scalars['Boolean']['input']>;
  helperText?: InputMaybe<Scalars['String']['input']>;
  stateAbbrev?: InputMaybe<Scalars['String']['input']>;
  quadrants?: InputMaybe<Scalars['Int']['input']>;
  matchupType?: InputMaybe<BracketMatchupTypeEnum>;
  bracketClassificationId?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
  playInGames?: InputMaybe<Scalars['Int']['input']>;
  nfhsNetworkUrl?: InputMaybe<Scalars['String']['input']>;
  gofanTicketingUrl?: InputMaybe<Scalars['String']['input']>;
  hasOneLocation?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminBracketDetailsMutation = {
  createEditBracket: { coverPhotoImage: string | undefined; id: string; logoImage: string | undefined; statusId: number };
};

export type AdminBracketPublishMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminBracketPublishMutation = { publishBracket: { statusId: number } };

export type AdminBracketQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminBracketQuery = {
  bracket: {
    autoAdvance: boolean;
    syncScores: boolean;
    coverPhotoImage: string | undefined;
    divisionText: string;
    formatId: number;
    matchupType: BracketMatchupTypeEnum;
    quadrants: number | undefined;
    nfhsNetworkUrl: string | undefined;
    gofanTicketingUrl: string | undefined;
    hasOneLocation: boolean;
    location: string | undefined;
    hideSeedNumbers: boolean;
    logoImage: string | undefined;
    name: string;
    numberOfTeams: number;
    helperText: string | undefined;
    bracketClassificationId: number | undefined;
    showHomeTeamFirst: boolean;
    showThirdPlaceGame: boolean;
    playInGames: number;
    slug: string | undefined;
    statusId: number;
    quadrantNames: Array<{ index: number | undefined; name: string | undefined }> | undefined;
    genderSport: { slug: string };
    rounds:
      | Array<{
          defaultName: string;
          endDate: string | undefined;
          id: BracketRoundIdEnum;
          isLoserRound: boolean;
          isNeutral: boolean;
          name: string | undefined;
          size: number;
          startDate: string | undefined;
          bracketContests: Array<{
            date: string | undefined;
            name: string | undefined;
            gameNumber: number;
            isNeutral: boolean;
            location: string | undefined;
            position: number;
            roundId: BracketRoundIdEnum;
            secondaryStatusId: number | undefined;
            statusId: number | undefined;
            away: {
              isBye: boolean;
              isTbd: boolean;
              isLoser: boolean;
              isOriginal: boolean;
              lineScore: Array<number> | undefined;
              shootoutScore: number | undefined;
              seed: string | undefined;
              score: number | undefined;
              team:
                | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
                | undefined;
              seriesTeamInfo:
                | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
                | undefined;
            };
            home: {
              isBye: boolean;
              isTbd: boolean;
              isLoser: boolean;
              isOriginal: boolean;
              lineScore: Array<number> | undefined;
              shootoutScore: number | undefined;
              seed: string | undefined;
              score: number | undefined;
              team:
                | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
                | undefined;
              seriesTeamInfo:
                | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
                | undefined;
            };
            seriesContests:
              | Array<{ date: string | undefined; statusId: number | undefined; secondaryStatusId: number | undefined }>
              | undefined;
          }>;
        }>
      | undefined;
    state: { abbrev: string } | undefined;
    userEntityInvitationEntities: Array<{
      id: string;
      invitation_status: string;
      role: string;
      user_entity_invitation: { invite_token: string; user: { id: string; username: string; image: string | undefined } | undefined };
    }>;
    users: Array<{ id: string }>;
  };
};

export type AdminBracketRoundsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  rounds: Array<BracketRoundInputObject> | BracketRoundInputObject;
  quadrants?: InputMaybe<Array<BracketQuadrantInputObject> | BracketQuadrantInputObject>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminBracketRoundsMutation = { updateBracketRounds: { statusId: number } };

export type AdminBracketsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  seasonName?: InputMaybe<Scalars['String']['input']>;
  sportId?: InputMaybe<Scalars['Int']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminBracketsQuery = {
  currentUser: {
    brackets: {
      pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
      nodes: Array<{
        id: string;
        name: string;
        statusId: number;
        webPath: string;
        genderSport: { name: string; slug: string; key: GenderSportEnum };
      }>;
    };
  };
};

export type AdminCopyBracketMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminCopyBracketMutation = { copyBracket: { id: string } };

export type AdminCreateEditTeamCoachMutationVariables = Exact<{
  team_id: Scalars['ID']['input'];
  input: TeamCoachInputType;
}>;

export type AdminCreateEditTeamCoachMutation = {
  createEditTeamCoach: {
    id: string;
    year_hired: number | undefined;
    coach_type: { id: number; name: string | undefined } | undefined;
    coach: {
      id: string;
      cover_image: string | undefined;
      image: string | undefined;
      first_name: string | undefined;
      last_name: string | undefined;
    };
  };
};

export type AdminCreateTeamMutationVariables = Exact<{
  input: TeamInputType;
}>;

export type AdminCreateTeamMutation = {
  createTeam: {
    id: string;
    division_text: string;
    games_count: number;
    has_roster: boolean | undefined;
    has_schedule: boolean | undefined;
    name: string;
    gender_sport: string | undefined;
    genderSport: {
      key: GenderSportEnum;
      name: string;
      genderId: number;
      sportId: number;
      gender: { id: number; name: string };
      sport: { id: number; name: string };
    };
    level: { id: number; name: string };
    owner: { id: string; username: string; image: string | undefined } | undefined;
    user_entity_invitation_entities: Array<{
      id: string;
      invitation_status: string;
      user_entity_invitation: { invite_token: string; email: string | undefined };
      entity: { id: string } | {};
    }>;
    previous_games:
      | {
          edges: Array<{
            node:
              | { id: string; date: string; title_text: string; away: { score: number }; home: { score: number } }
              | { id: string; date: string; title_text: string; away: { score: number }; home: { score: number } }
              | { id: string; date: string; title_text: string; away: { score: number }; home: { score: number } };
          }>;
        }
      | undefined;
  };
};

export type AdminDeleteInvitesMutationVariables = Exact<{
  inviteIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  entityType?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminDeleteInvitesMutation = { deleteInvites: boolean };

export type AdminDeleteTeamCoachMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminDeleteTeamCoachMutation = { deleteTeamCoach: { id: string } };

export type AdminGamesCreateEditGamePlayerStatsMutationVariables = Exact<{
  team_id: Scalars['ID']['input'];
  input: GamePlayerStatsInputType;
}>;

export type AdminGamesCreateEditGamePlayerStatsMutation = { createEditGamePlayerStats: { id: string } };

export type EditGameFragment = {
  date: string;
  is_neutral: boolean | undefined;
  location: string | undefined;
  status_id: number;
  secondary_status_id: number | undefined;
  game_type: { id: number; name: string | undefined } | undefined;
};

export type GameTeamFragment = {
  id: string;
  is_home: boolean;
  team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; location_text: string };
};

export type AdminGamesCreateEditTeamGameMutationVariables = Exact<{
  team_id: Scalars['Int']['input'];
  input: TeamGameInputType;
}>;

export type AdminGamesCreateEditTeamGameMutation = {
  createEditTeamGame:
    | {
        featured: {
          id: string;
          is_home: boolean;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; location_text: string };
        };
        opponent: {
          id: string;
          is_home: boolean;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; location_text: string };
        };
      }
    | {
        featured: {
          id: string;
          is_home: boolean;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; location_text: string };
        };
        opponent: {
          id: string;
          is_home: boolean;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; location_text: string };
        };
      }
    | {
        date: string;
        is_neutral: boolean | undefined;
        location: string | undefined;
        status_id: number;
        secondary_status_id: number | undefined;
        featured: {
          id: string;
          is_home: boolean;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; location_text: string };
        };
        opponent: {
          id: string;
          is_home: boolean;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; location_text: string };
        };
        game_type: { id: number; name: string | undefined } | undefined;
      };
};

export type AdminGamesTeamQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['Int']['input']>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminGamesTeamQuery = {
  team: {
    team_season:
      | {
          season: { id: number; school_year: { full_name: string | undefined; name: string } | undefined };
          games:
            | Array<
                | {
                    id: string;
                    date: string;
                    division_text: string;
                    facebook_path: string | undefined;
                    is_neutral: boolean | undefined;
                    location: string | undefined;
                    medium_status_text: string | undefined;
                    periods: number;
                    results_only: boolean | undefined;
                    secondary_status_id: number | undefined;
                    short_status_text: string | undefined;
                    status_id: number;
                    twitter_path: string | undefined;
                    web_path: string;
                    location_text: string | undefined;
                    game_type_label: string | undefined;
                    short_result: string | undefined;
                    score_text: string | undefined;
                    game_type: { id: number } | undefined;
                    game_setting: { rule_type_id: number } | undefined;
                    season: { id: number; name: string } | undefined;
                    featured: {
                      is_home: boolean;
                      forfeit: boolean | undefined;
                      linescore: string | undefined;
                      score: number;
                      shootout_score: number | undefined;
                      standing: { overallRecord: string; leagueRecord: string };
                    };
                    opponent: {
                      id: string;
                      forfeit: boolean | undefined;
                      linescore: string | undefined;
                      score: number;
                      shootout_score: number | undefined;
                      team: {
                        id: string;
                        abbrev: string;
                        image: string | undefined;
                        mascot: string | undefined;
                        name: string;
                        web_path: string;
                      };
                    };
                  }
                | {
                    id: string;
                    date: string;
                    division_text: string;
                    facebook_path: string | undefined;
                    is_neutral: boolean | undefined;
                    location: string | undefined;
                    medium_status_text: string | undefined;
                    periods: number;
                    results_only: boolean | undefined;
                    secondary_status_id: number | undefined;
                    short_status_text: string | undefined;
                    status_id: number;
                    twitter_path: string | undefined;
                    web_path: string;
                    location_text: string | undefined;
                    game_type_label: string | undefined;
                    short_result: string | undefined;
                    score_text: string | undefined;
                    game_type: { id: number } | undefined;
                    game_setting: { rule_type_id: number } | undefined;
                    season: { id: number; name: string } | undefined;
                    featured: {
                      is_home: boolean;
                      forfeit: boolean | undefined;
                      linescore: string | undefined;
                      score: number;
                      shootout_score: number | undefined;
                      standing: { overallRecord: string; leagueRecord: string };
                    };
                    opponent: {
                      id: string;
                      forfeit: boolean | undefined;
                      linescore: string | undefined;
                      score: number;
                      shootout_score: number | undefined;
                      team: {
                        id: string;
                        abbrev: string;
                        image: string | undefined;
                        mascot: string | undefined;
                        name: string;
                        web_path: string;
                      };
                    };
                  }
                | {
                    id: string;
                    date: string;
                    division_text: string;
                    facebook_path: string | undefined;
                    is_neutral: boolean | undefined;
                    location: string | undefined;
                    medium_status_text: string | undefined;
                    periods: number;
                    results_only: boolean | undefined;
                    secondary_status_id: number | undefined;
                    short_status_text: string | undefined;
                    status_id: number;
                    twitter_path: string | undefined;
                    web_path: string;
                    location_text: string | undefined;
                    game_type_label: string | undefined;
                    short_result: string | undefined;
                    score_text: string | undefined;
                    game_type: { id: number } | undefined;
                    game_setting: { rule_type_id: number } | undefined;
                    season: { id: number; name: string } | undefined;
                    featured: {
                      is_home: boolean;
                      forfeit: boolean | undefined;
                      linescore: string | undefined;
                      score: number;
                      shootout_score: number | undefined;
                      standing: { overallRecord: string; leagueRecord: string };
                    };
                    opponent: {
                      id: string;
                      forfeit: boolean | undefined;
                      linescore: string | undefined;
                      score: number;
                      shootout_score: number | undefined;
                      team: {
                        id: string;
                        abbrev: string;
                        image: string | undefined;
                        mascot: string | undefined;
                        name: string;
                        web_path: string;
                      };
                    };
                  }
              >
            | undefined;
        }
      | undefined;
  };
};

export type AdminSchoolQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminSchoolQuery = {
  currentUser: {
    school: {
      id: string;
      abbrev: string | undefined;
      name: string;
      mascot: string | undefined;
      image: string | undefined;
      cover_image: string | undefined;
      web_path: string;
      twitter: string | undefined;
      facebook: string | undefined;
      instagram: string | undefined;
      principal: string | undefined;
      primary_color: string | undefined;
      secondary_color: string | undefined;
      athletic_director: string | undefined;
      locationText: string;
      enrollment_count: number | undefined;
      owner: { id: string } | undefined;
    };
  };
};

export type AdminSchoolTeamsAdminQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type AdminSchoolTeamsAdminQuery = {
  school: {
    user_entities: Array<{
      id: string;
      role_id: number;
      status_id: number | undefined;
      role: string;
      user: { id: string; image: string | undefined; username: string };
    }>;
    teams: Array<{
      id: string;
      user_entity_invitation_entities: Array<{
        id: string;
        invitation_status: string;
        user_entity_invitation: { email: string | undefined; invite_token: string };
        entity: { id: string } | {};
      }>;
    }>;
  };
};

export type AdminSchoolTeamsClientQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type AdminSchoolTeamsClientQuery = {
  school: {
    user_entities: Array<{ id: string; role_id: number; status_id: number | undefined; role: string; user: { id: string } }>;
    teams: Array<{
      id: string;
      user_entity_invitation_entities: Array<{
        id: string;
        invitation_status: string;
        user_entity_invitation: { email: string | undefined; invite_token: string };
        entity: { id: string } | {};
      }>;
    }>;
  };
};

export type AdminSchoolTeamsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminSchoolTeamsQuery = {
  currentUser: {
    school: {
      id: string;
      name: string;
      mascot: string | undefined;
      image: string | undefined;
      web_path: string;
      location_text: string;
      locationText: string;
      state: { name: string; abbrev: string } | undefined;
      owner: { id: string; username: string } | undefined;
      teams: Array<{
        id: string;
        name: string;
        games_count: number;
        has_roster: boolean | undefined;
        has_schedule: boolean | undefined;
        division_text: string;
        level: { id: number; name: string };
        owner: { id: string; username: string; image: string | undefined } | undefined;
        genderSport: {
          key: GenderSportEnum;
          name: string;
          genderId: number;
          sportId: number;
          gender: { id: number; name: string };
          sport: { id: number; name: string };
        };
        previous_games:
          | {
              edges: Array<{
                node:
                  | { id: string; title_text: string; date: string; home: { score: number }; away: { score: number } }
                  | { id: string; title_text: string; date: string; home: { score: number }; away: { score: number } }
                  | { id: string; title_text: string; date: string; home: { score: number }; away: { score: number } };
              }>;
            }
          | undefined;
      }>;
    };
  };
};

export type AdminTeamCoachesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminTeamCoachesQuery = {
  team: {
    team_coaches:
      | Array<{
          id: string;
          year_hired: number | undefined;
          coach_type: { id: number; name: string | undefined } | undefined;
          coach: {
            id: string;
            cover_image: string | undefined;
            image: string | undefined;
            first_name: string | undefined;
            last_name: string | undefined;
          };
        }>
      | undefined;
  };
};

export type AdminTeamInvitationMutationVariables = Exact<{
  entities: Array<UserEntityInvitationEntityInputType> | UserEntityInvitationEntityInputType;
}>;

export type AdminTeamInvitationMutation = {
  createUserEntityInvitation: {
    id: string;
    invite_token: string;
    entities: Array<{ id: string; invitation_status: string; role: string }>;
  };
};

export type AdminTeamQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminTeamQuery = {
  team: {
    club_house: string | undefined;
    cover_image: string | undefined;
    facebook: string | undefined;
    hashtag: string | undefined;
    instagram: string | undefined;
    name: string;
    school_id: number | undefined;
    stadium_capacity: number | undefined;
    twitter: string | undefined;
    team_rivals:
      | Array<{ id: string; rival: { id: string; image: string | undefined; mascot: string | undefined; name: string } | undefined }>
      | undefined;
    sport: { name: string } | undefined;
  };
};

export type AdminTeamsCreateEditTeamGameMutationVariables = Exact<{
  team_id: Scalars['Int']['input'];
  input: TeamGameInputType;
}>;

export type AdminTeamsCreateEditTeamGameMutation = {
  createEditTeamGame:
    | {
        id: string;
        date: string;
        division_text: string;
        facebook_path: string | undefined;
        is_neutral: boolean | undefined;
        location: string | undefined;
        medium_status_text: string | undefined;
        periods: number;
        results_only: boolean | undefined;
        secondary_status_id: number | undefined;
        short_status_text: string | undefined;
        status_id: number;
        twitter_path: string | undefined;
        web_path: string;
        location_text: string | undefined;
        game_type_label: string | undefined;
        short_result: string | undefined;
        score_text: string | undefined;
        game_type: { id: number } | undefined;
        game_setting: { rule_type_id: number } | undefined;
        season: { id: number; name: string } | undefined;
        featured: {
          is_home: boolean;
          forfeit: boolean | undefined;
          linescore: string | undefined;
          score: number;
          shootout_score: number | undefined;
          standing: { overallRecord: string; leagueRecord: string };
        };
        opponent: {
          id: string;
          forfeit: boolean | undefined;
          linescore: string | undefined;
          score: number;
          shootout_score: number | undefined;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; web_path: string };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        facebook_path: string | undefined;
        is_neutral: boolean | undefined;
        location: string | undefined;
        medium_status_text: string | undefined;
        periods: number;
        results_only: boolean | undefined;
        secondary_status_id: number | undefined;
        short_status_text: string | undefined;
        status_id: number;
        twitter_path: string | undefined;
        web_path: string;
        location_text: string | undefined;
        game_type_label: string | undefined;
        short_result: string | undefined;
        score_text: string | undefined;
        game_type: { id: number } | undefined;
        game_setting: { rule_type_id: number } | undefined;
        season: { id: number; name: string } | undefined;
        featured: {
          is_home: boolean;
          forfeit: boolean | undefined;
          linescore: string | undefined;
          score: number;
          shootout_score: number | undefined;
          standing: { overallRecord: string; leagueRecord: string };
        };
        opponent: {
          id: string;
          forfeit: boolean | undefined;
          linescore: string | undefined;
          score: number;
          shootout_score: number | undefined;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; web_path: string };
        };
      }
    | {
        id: string;
        date: string;
        division_text: string;
        facebook_path: string | undefined;
        is_neutral: boolean | undefined;
        location: string | undefined;
        medium_status_text: string | undefined;
        periods: number;
        results_only: boolean | undefined;
        secondary_status_id: number | undefined;
        short_status_text: string | undefined;
        status_id: number;
        twitter_path: string | undefined;
        web_path: string;
        location_text: string | undefined;
        game_type_label: string | undefined;
        short_result: string | undefined;
        score_text: string | undefined;
        game_type: { id: number } | undefined;
        game_setting: { rule_type_id: number } | undefined;
        season: { id: number; name: string } | undefined;
        featured: {
          is_home: boolean;
          forfeit: boolean | undefined;
          linescore: string | undefined;
          score: number;
          shootout_score: number | undefined;
          standing: { overallRecord: string; leagueRecord: string };
        };
        opponent: {
          id: string;
          forfeit: boolean | undefined;
          linescore: string | undefined;
          score: number;
          shootout_score: number | undefined;
          team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; web_path: string };
        };
      };
};

export type AdminTeamsCreateEditTeamPlayerMutationVariables = Exact<{
  team_id: Scalars['ID']['input'];
  input: TeamPlayerInputType;
}>;

export type AdminTeamsCreateEditTeamPlayerMutation = {
  createEditTeamPlayer: {
    id: string;
    active: boolean;
    jersey_number: string | undefined;
    position: { id: string; abbrev: string } | undefined;
    position_2: { id: string; abbrev: string } | undefined;
    player: {
      id: string;
      class_year: number | undefined;
      class_year_abbrev: string | undefined;
      cover_image: string | undefined;
      image: string | undefined;
      first_name: string | undefined;
      height: number | undefined;
      height_text: string | undefined;
      last_name: string | undefined;
      weight: number | undefined;
      mappings: Array<{ displayName: string | undefined }> | undefined;
    };
  };
};

export type AdminTeamsSearchQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  level_id?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  section_id?: InputMaybe<Scalars['Int']['input']>;
  show_all?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminTeamsSearchQuery = {
  teams: {
    nodes: Array<{
      id: string;
      abbrev: string;
      division_text: string;
      image: string | undefined;
      location_text: string;
      mascot: string | undefined;
      name: string;
    }>;
  };
};

export type AdminTeamsSearchQueryTeamsFragment = {
  nodes: Array<{
    id: string;
    abbrev: string;
    division_text: string;
    image: string | undefined;
    location_text: string;
    mascot: string | undefined;
    name: string;
  }>;
};

export type AdminTeamsTeamPlayersQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminTeamsTeamPlayersQuery = {
  team: {
    gender_id: number;
    team_season:
      | {
          season: { id: number; school_year: { full_name: string | undefined; name: string } | undefined };
          teamPlayerRosters:
            | Array<{
                teamPlayer: {
                  id: string;
                  active: boolean;
                  jersey_number: string | undefined;
                  position: { id: string; abbrev: string } | undefined;
                  position_2: { id: string; abbrev: string } | undefined;
                  player: {
                    id: string;
                    class_year: number | undefined;
                    class_year_abbrev: string | undefined;
                    cover_image: string | undefined;
                    image: string | undefined;
                    first_name: string | undefined;
                    height: number | undefined;
                    height_text: string | undefined;
                    last_name: string | undefined;
                    weight: number | undefined;
                    mappings: Array<{ displayName: string | undefined }> | undefined;
                  };
                };
              }>
            | undefined;
        }
      | undefined;
  };
};

export type AdminTeamsTeamQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AdminTeamsTeamQuery = {
  team: {
    gender: { name: string } | undefined;
    level: { name: string };
    school: { name: string } | undefined;
    sport: { name: string } | undefined;
    owner: { id: string; username: string; image: string | undefined } | undefined;
    user_entities: Array<{
      id: string;
      status_id: number | undefined;
      role: string;
      user: { id: string; image: string | undefined; username: string };
    }>;
    user_entity_invitation_entities: Array<{
      id: string;
      invitation_status: string;
      role: string;
      user_entity_invitation: { invite_token: string; user: { id: string; username: string; image: string | undefined } | undefined };
    }>;
  };
};

export type AdminTeamsTeamQueryTeamFragment = {
  gender: { name: string } | undefined;
  level: { name: string };
  school: { name: string } | undefined;
  sport: { name: string } | undefined;
  owner: { id: string; username: string; image: string | undefined } | undefined;
  user_entities: Array<{
    id: string;
    status_id: number | undefined;
    role: string;
    user: { id: string; image: string | undefined; username: string };
  }>;
  user_entity_invitation_entities: Array<{
    id: string;
    invitation_status: string;
    role: string;
    user_entity_invitation: { invite_token: string; user: { id: string; username: string; image: string | undefined } | undefined };
  }>;
};

export type SchoolFragment = {
  id: string;
  abbrev: string | undefined;
  name: string;
  image: string | undefined;
  cover_image: string | undefined;
  web_path: string;
  twitter: string | undefined;
  facebook: string | undefined;
  instagram: string | undefined;
  principal: string | undefined;
  primary_color: string | undefined;
  secondary_color: string | undefined;
  athletic_director: string | undefined;
  enrollment_count: number | undefined;
};

export type AdminUpdateSchoolMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SchoolInputType;
}>;

export type AdminUpdateSchoolMutation = {
  updateSchool: {
    id: string;
    abbrev: string | undefined;
    name: string;
    image: string | undefined;
    cover_image: string | undefined;
    web_path: string;
    twitter: string | undefined;
    facebook: string | undefined;
    instagram: string | undefined;
    principal: string | undefined;
    primary_color: string | undefined;
    secondary_color: string | undefined;
    athletic_director: string | undefined;
    enrollment_count: number | undefined;
  };
};

export type AdminUpdateTeamGameResultsMutationVariables = Exact<{
  team_id: Scalars['Int']['input'];
  input: TeamGameResultsInputType;
}>;

export type AdminUpdateTeamGameResultsMutation = {
  updateTeamGameResults:
    | { id: string; featured: { id: string }; opponent: { id: string } }
    | { id: string; featured: { id: string }; opponent: { id: string } }
    | { id: string; featured: { id: string }; opponent: { id: string } };
};

export type AdminUpdateTeamMutationVariables = Exact<{
  team_id: Scalars['ID']['input'];
  input: TeamInputType;
}>;

export type AdminUpdateTeamMutation = {
  updateTeam: {
    club_house: string | undefined;
    cover_image: string | undefined;
    facebook: string | undefined;
    hashtag: string | undefined;
    instagram: string | undefined;
    name: string;
    school_id: number | undefined;
    stadium_capacity: number | undefined;
    twitter: string | undefined;
    team_rivals:
      | Array<{ id: string; rival: { id: string; image: string | undefined; mascot: string | undefined; name: string } | undefined }>
      | undefined;
    sport: { name: string } | undefined;
  };
};

export type BracketPickEmsQueryVariables = Exact<{
  bracketId: Scalars['ID']['input'];
}>;

export type BracketPickEmsQuery = {
  currentUser: {
    pickEms: Array<{
      id: string;
      result: PickEmResultTypeEnum | undefined;
      contest: {
        id: string;
        contestParticipants: Array<{
          id: string;
          participant: { __typename: 'ParticipantPlaceholder' } | { __typename: 'Team'; id: string };
        }>;
      };
      team: { id: string; name: string };
    }>;
    bracketLeaderboard:
      | {
          id: string;
          correctPickEmPercentage: number;
          correctPickEmsCount: number;
          incorrectPickEmsCount: number;
          place: number | undefined;
          pointsEarned: number;
          pointsPossible: number;
          tieBreakerPoints: number | undefined;
        }
      | undefined;
  };
};

export type CheerGameTeamMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  decrement_id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CheerGameTeamMutation = { cheerGameTeam: { id: string; cheers: number; picks: number } };

export type ClaimTeamAsOwnerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ClaimTeamAsOwnerMutation = {
  claimTeamAsSchoolOwner: { id: string; owner: { id: string; image: string | undefined; username: string } | undefined };
};

export type CreateContestPickEmMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CreateContestPickEmMutation = { createContestPickEm: { id: string; votes: number } };

export type CreateContestPickEmsMutationVariables = Exact<{
  contestParticipantIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type CreateContestPickEmsMutation = {
  createContestPickEms: Array<{ id: string; contest: { id: string; contestParticipants: Array<{ id: string; votes: number }> } }>;
};

export type CreateGamePickEmMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CreateGamePickEmMutation = { createGamePickEm: { gameTeams: Array<{ picks: number }> } };

export type CreateTeamRivalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  rival_id: Scalars['ID']['input'];
}>;

export type CreateTeamRivalMutation = {
  createTeamRival: { id: string; rival: { id: string; image: string | undefined; mascot: string | undefined; name: string } | undefined };
};

export type CreateUserEntityInvitationMutationVariables = Exact<{
  entities: Array<UserEntityInvitationEntityInputType> | UserEntityInvitationEntityInputType;
}>;

export type CreateUserEntityInvitationMutation = { createUserEntityInvitation: { id: string; invite_token: string } };

export type DeleteContestPickEmsMutationVariables = Exact<{
  pickEmIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type DeleteContestPickEmsMutation = {
  deleteContestPickEms: Array<{ id: string; contest: { id: string; contestParticipants: Array<{ id: string; votes: number }> } }>;
};

export type DeleteOwnerInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  entityType?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeleteOwnerInviteMutation = { deleteOwnerInvite: boolean };

export type DeleteTeamGameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTeamGameMutation = { deleteTeamGame: { id: string } | { id: string } | { id: string } };

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTeamMutation = { deleteTeam: boolean };

export type DeleteTeamOwnerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTeamOwnerMutation = { deleteTeamOwner: { id: string } };

export type DeleteTeamPlayerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTeamPlayerMutation = { deleteTeamPlayer: { id: string } };

export type DeleteUserEntityMutationVariables = Exact<{
  user_id: Scalars['ID']['input'];
  entity_id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
}>;

export type DeleteUserEntityMutation = { deleteUserEntity: { id: string } };

export type BracketRoundFragment = {
  defaultName: string;
  endDate: string | undefined;
  id: BracketRoundIdEnum;
  isLoserRound: boolean;
  isNeutral: boolean;
  name: string | undefined;
  size: number;
  startDate: string | undefined;
  bracketContests: Array<{
    date: string | undefined;
    name: string | undefined;
    gameNumber: number;
    isNeutral: boolean;
    location: string | undefined;
    position: number;
    roundId: BracketRoundIdEnum;
    secondaryStatusId: number | undefined;
    statusId: number | undefined;
    away: {
      isBye: boolean;
      isTbd: boolean;
      isLoser: boolean;
      isOriginal: boolean;
      lineScore: Array<number> | undefined;
      shootoutScore: number | undefined;
      seed: string | undefined;
      score: number | undefined;
      team:
        | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
        | undefined;
      seriesTeamInfo:
        | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
        | undefined;
    };
    home: {
      isBye: boolean;
      isTbd: boolean;
      isLoser: boolean;
      isOriginal: boolean;
      lineScore: Array<number> | undefined;
      shootoutScore: number | undefined;
      seed: string | undefined;
      score: number | undefined;
      team:
        | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
        | undefined;
      seriesTeamInfo:
        | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
        | undefined;
    };
    seriesContests: Array<{ date: string | undefined; statusId: number | undefined; secondaryStatusId: number | undefined }> | undefined;
  }>;
};

export type BracketContestTeamFragment = {
  isBye: boolean;
  isTbd: boolean;
  isLoser: boolean;
  isOriginal: boolean;
  lineScore: Array<number> | undefined;
  shootoutScore: number | undefined;
  seed: string | undefined;
  score: number | undefined;
  team:
    | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
    | undefined;
  seriesTeamInfo: Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }> | undefined;
};

type GameFragmentBasketballGame = {
  id: string;
  date: string;
  division_text: string;
  facebook_path: string | undefined;
  is_neutral: boolean | undefined;
  location: string | undefined;
  medium_status_text: string | undefined;
  periods: number;
  results_only: boolean | undefined;
  secondary_status_id: number | undefined;
  short_status_text: string | undefined;
  status_id: number;
  twitter_path: string | undefined;
  web_path: string;
  location_text: string | undefined;
  game_type_label: string | undefined;
  short_result: string | undefined;
  score_text: string | undefined;
  game_type: { id: number } | undefined;
  game_setting: { rule_type_id: number } | undefined;
  season: { id: number; name: string } | undefined;
  featured: {
    is_home: boolean;
    forfeit: boolean | undefined;
    linescore: string | undefined;
    score: number;
    shootout_score: number | undefined;
    standing: { overallRecord: string; leagueRecord: string };
  };
  opponent: {
    id: string;
    forfeit: boolean | undefined;
    linescore: string | undefined;
    score: number;
    shootout_score: number | undefined;
    team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; web_path: string };
  };
};

type GameFragmentFootballGame = {
  id: string;
  date: string;
  division_text: string;
  facebook_path: string | undefined;
  is_neutral: boolean | undefined;
  location: string | undefined;
  medium_status_text: string | undefined;
  periods: number;
  results_only: boolean | undefined;
  secondary_status_id: number | undefined;
  short_status_text: string | undefined;
  status_id: number;
  twitter_path: string | undefined;
  web_path: string;
  location_text: string | undefined;
  game_type_label: string | undefined;
  short_result: string | undefined;
  score_text: string | undefined;
  game_type: { id: number } | undefined;
  game_setting: { rule_type_id: number } | undefined;
  season: { id: number; name: string } | undefined;
  featured: {
    is_home: boolean;
    forfeit: boolean | undefined;
    linescore: string | undefined;
    score: number;
    shootout_score: number | undefined;
    standing: { overallRecord: string; leagueRecord: string };
  };
  opponent: {
    id: string;
    forfeit: boolean | undefined;
    linescore: string | undefined;
    score: number;
    shootout_score: number | undefined;
    team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; web_path: string };
  };
};

type GameFragmentGame = {
  id: string;
  date: string;
  division_text: string;
  facebook_path: string | undefined;
  is_neutral: boolean | undefined;
  location: string | undefined;
  medium_status_text: string | undefined;
  periods: number;
  results_only: boolean | undefined;
  secondary_status_id: number | undefined;
  short_status_text: string | undefined;
  status_id: number;
  twitter_path: string | undefined;
  web_path: string;
  location_text: string | undefined;
  game_type_label: string | undefined;
  short_result: string | undefined;
  score_text: string | undefined;
  game_type: { id: number } | undefined;
  game_setting: { rule_type_id: number } | undefined;
  season: { id: number; name: string } | undefined;
  featured: {
    is_home: boolean;
    forfeit: boolean | undefined;
    linescore: string | undefined;
    score: number;
    shootout_score: number | undefined;
    standing: { overallRecord: string; leagueRecord: string };
  };
  opponent: {
    id: string;
    forfeit: boolean | undefined;
    linescore: string | undefined;
    score: number;
    shootout_score: number | undefined;
    team: { id: string; abbrev: string; image: string | undefined; mascot: string | undefined; name: string; web_path: string };
  };
};

export type GameFragment = GameFragmentBasketballGame | GameFragmentFootballGame | GameFragmentGame;

export type TeamCoachFragment = {
  id: string;
  year_hired: number | undefined;
  coach_type: { id: number; name: string | undefined } | undefined;
  coach: {
    id: string;
    cover_image: string | undefined;
    image: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
  };
};

export type TeamFragment = {
  club_house: string | undefined;
  cover_image: string | undefined;
  facebook: string | undefined;
  hashtag: string | undefined;
  instagram: string | undefined;
  name: string;
  school_id: number | undefined;
  stadium_capacity: number | undefined;
  twitter: string | undefined;
  team_rivals:
    | Array<{ id: string; rival: { id: string; image: string | undefined; mascot: string | undefined; name: string } | undefined }>
    | undefined;
  sport: { name: string } | undefined;
};

export type TeamPlayerFragment = {
  id: string;
  active: boolean;
  jersey_number: string | undefined;
  position: { id: string; abbrev: string } | undefined;
  position_2: { id: string; abbrev: string } | undefined;
  player: {
    id: string;
    class_year: number | undefined;
    class_year_abbrev: string | undefined;
    cover_image: string | undefined;
    image: string | undefined;
    first_name: string | undefined;
    height: number | undefined;
    height_text: string | undefined;
    last_name: string | undefined;
    weight: number | undefined;
    mappings: Array<{ displayName: string | undefined }> | undefined;
  };
};

export type UserEntityFragment = {
  id: string;
  entity_type: string;
  role_id: number;
  status_id: number | undefined;
  entity:
    | { __typename: 'Bracket' }
    | { __typename: 'Group' }
    | {
        __typename: 'School';
        id: string;
        image: string | undefined;
        name: string;
        city: string | undefined;
        web_path: string;
        state: { abbrev: string } | undefined;
        owner: { id: string } | undefined;
      }
    | {
        __typename: 'Team';
        id: string;
        sport_id: number;
        gender_id: number;
        level_id: number;
        image: string | undefined;
        name: string;
        mascot: string | undefined;
        short_division_text: string;
        division_text: string;
        web_path: string;
        notification_count: string | undefined;
        gender_sport: string | undefined;
        owner: { id: string } | undefined;
      };
};

export type GamePickEmQueryVariables = Exact<{
  contestId: Scalars['ID']['input'];
}>;

export type GamePickEmQuery = {
  currentUser: {
    pickEm:
      | {
          id: string;
          contest: {
            contestParticipants: Array<{
              id: string;
              participant: { __typename: 'ParticipantPlaceholder' } | { __typename: 'Team'; id: string; name: string };
            }>;
          };
          team: { id: string };
        }
      | undefined;
  };
};

export type GetTeamOwnerEmailQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetTeamOwnerEmailQuery = { team: { owner: { email: string } | undefined } };

export type PhotoGalleriesSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  genderSport?: InputMaybe<GenderSportEnum>;
  state?: InputMaybe<StateEnum>;
}>;

export type PhotoGalleriesSearchQuery = {
  photoGalleries: {
    nodes: Array<{
      id: string;
      name: string;
      date: string;
      genderSport: { name: string } | undefined;
      photographer: { email: string; id: string };
    }>;
  };
};

export type RankingTeamsSearchQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RankingTeamsSearchQuery = {
  ranking:
    | {
        teamRankings: {
          nodes: Array<{
            id: string;
            filteredChange: number | undefined;
            filteredPlace: number | undefined;
            points: number | undefined;
            record: string;
            streak: string | undefined;
            strengthOfSchedule: number | undefined;
            top100Record: string | undefined;
            team: {
              abbrev: string;
              image: string | undefined;
              locationText: string;
              mascot: string | undefined;
              name: string;
              webPath: string;
            };
          }>;
        };
      }
    | undefined;
};

export type RegisterUserQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}>;

export type RegisterUserQuery = { user: boolean };

export type RemoveBracketGamesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  roundId: BracketRoundIdEnum;
  positions: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type RemoveBracketGamesMutation = {
  removeBracketGames: {
    playInGames: number;
    rounds:
      | Array<{
          defaultName: string;
          endDate: string | undefined;
          id: BracketRoundIdEnum;
          isLoserRound: boolean;
          isNeutral: boolean;
          name: string | undefined;
          size: number;
          startDate: string | undefined;
          bracketContests: Array<{
            date: string | undefined;
            name: string | undefined;
            gameNumber: number;
            isNeutral: boolean;
            location: string | undefined;
            position: number;
            roundId: BracketRoundIdEnum;
            secondaryStatusId: number | undefined;
            statusId: number | undefined;
            away: {
              isBye: boolean;
              isTbd: boolean;
              isLoser: boolean;
              isOriginal: boolean;
              lineScore: Array<number> | undefined;
              shootoutScore: number | undefined;
              seed: string | undefined;
              score: number | undefined;
              team:
                | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
                | undefined;
              seriesTeamInfo:
                | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
                | undefined;
            };
            home: {
              isBye: boolean;
              isTbd: boolean;
              isLoser: boolean;
              isOriginal: boolean;
              lineScore: Array<number> | undefined;
              shootoutScore: number | undefined;
              seed: string | undefined;
              score: number | undefined;
              team:
                | { abbrev: string; id: string; image: string | undefined; name: string; mascot: string | undefined; locationText: string }
                | undefined;
              seriesTeamInfo:
                | Array<{ score: number | undefined; lineScore: Array<number> | undefined; shootoutScore: number | undefined }>
                | undefined;
            };
            seriesContests:
              | Array<{ date: string | undefined; statusId: number | undefined; secondaryStatusId: number | undefined }>
              | undefined;
          }>;
        }>
      | undefined;
  };
};

export type SearchClientQueryVariables = Exact<{
  query: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SearchClientQuery = {
  search: {
    nodes: Array<
      | { __typename: 'School'; id: string; name: string; image: string | undefined; location_text: string; web_path: string }
      | { __typename: 'SchoolOrganization' }
      | { __typename: 'Team' }
      | { __typename: 'TeamOrganization' }
    >;
  };
};

export type StandingsSearchQueryVariables = Exact<{
  genderId?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  sportId?: InputMaybe<Scalars['Int']['input']>;
  stateAbbrev?: InputMaybe<Scalars['String']['input']>;
}>;

export type StandingsSearchQuery = {
  search: {
    nodes: Array<
      | { __typename: 'School' }
      | { __typename: 'SchoolOrganization' }
      | {
          __typename: 'Team';
          id: string;
          image: string | undefined;
          locationText: string;
          name: string;
          league: { id: string } | undefined;
        }
      | {
          __typename: 'TeamOrganization';
          id: string;
          fullName: string | undefined;
          image: string | undefined;
          name: string;
          state: { name: string };
        }
    >;
  };
};

export type SyncRosterMutationVariables = Exact<{
  game_team_id: Scalars['ID']['input'];
}>;

export type SyncRosterMutation = { syncRoster: boolean };

export type GamePlayerFragment = {
  pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
  edges: Array<{
    node: {
      opponent: { team: { abbrev: string; image: string | undefined; name: string } };
      game: { date: string; web_path: string; home: { team_id: number } };
      stat:
        | { points_per_game: number | undefined }
        | {
            free_throws: string | undefined;
            free_throw_percentage: number | undefined;
            personal_fouls: number | undefined;
            two_points_made: number | undefined;
            three_points_made: number | undefined;
            points_per_game: number | undefined;
          }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined }
        | { points_per_game: number | undefined };
    };
  }>;
};

export type TeamPlayerQueryVariables = Exact<{
  player_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type TeamPlayerQuery = {
  team_player: {
    team_player_season:
      | {
          game_players:
            | {
                pageInfo: { endCursor: string | undefined; hasNextPage: boolean };
                edges: Array<{
                  node: {
                    opponent: { team: { abbrev: string; image: string | undefined; name: string } };
                    game: { date: string; web_path: string; home: { team_id: number } };
                    stat:
                      | { points_per_game: number | undefined }
                      | {
                          free_throws: string | undefined;
                          free_throw_percentage: number | undefined;
                          personal_fouls: number | undefined;
                          two_points_made: number | undefined;
                          three_points_made: number | undefined;
                          points_per_game: number | undefined;
                        }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined }
                      | { points_per_game: number | undefined };
                  };
                }>;
              }
            | undefined;
        }
      | undefined;
  };
};

export type UpdateBracketTieBreakerPointsMutationVariables = Exact<{
  bracketId: Scalars['ID']['input'];
  tieBreakerPoints: Scalars['Int']['input'];
}>;

export type UpdateBracketTieBreakerPointsMutation = {
  updateBracketTieBreakerPoints: { bracketLeaderboard: { id: string; tieBreakerPoints: number | undefined } | undefined };
};

export type UpdateContestPickEmsMutationVariables = Exact<{
  contestParticipantIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  pickEmIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  skipCreate: Scalars['Boolean']['input'];
  skipDelete: Scalars['Boolean']['input'];
}>;

export type UpdateContestPickEmsMutation = {
  createContestPickEms?: Array<{ id: string; contest: { id: string; contestParticipants: Array<{ id: string; votes: number }> } }>;
  deleteContestPickEms?: Array<{ id: string; contest: { id: string; contestParticipants: Array<{ id: string; votes: number }> } }>;
};

export type UserGamesQueryVariables = Exact<{ [key: string]: never }>;

export type UserGamesQuery = {
  currentUser: {
    bracketLeaderboards: {
      nodes: Array<{
        place: number | undefined;
        bracket: { name: string; logoImage: string | undefined; startDate: string | undefined; statusId: number; webPickEmPath: string };
      }>;
    };
  };
};

export type WebClaimEntityMutationVariables = Exact<{
  entity: ClaimableEntity;
}>;

export type WebClaimEntityMutation = {
  claimEntity: {
    id: string;
    entity_type: string;
    role_id: number;
    status_id: number | undefined;
    entity:
      | { __typename: 'Bracket' }
      | { __typename: 'Group' }
      | {
          __typename: 'School';
          id: string;
          image: string | undefined;
          name: string;
          city: string | undefined;
          web_path: string;
          state: { abbrev: string } | undefined;
          owner: { id: string } | undefined;
        }
      | {
          __typename: 'Team';
          id: string;
          sport_id: number;
          gender_id: number;
          level_id: number;
          image: string | undefined;
          name: string;
          mascot: string | undefined;
          short_division_text: string;
          division_text: string;
          web_path: string;
          notification_count: string | undefined;
          gender_sport: string | undefined;
          owner: { id: string } | undefined;
        };
  };
};

export type WebFollowEntityMutationVariables = Exact<{
  entity: FollowableEntity;
}>;

export type WebFollowEntityMutation = {
  followEntity: {
    id: string;
    entity_type: string;
    role_id: number;
    status_id: number | undefined;
    entity:
      | { __typename: 'Bracket' }
      | { __typename: 'Group' }
      | {
          __typename: 'School';
          id: string;
          image: string | undefined;
          name: string;
          city: string | undefined;
          web_path: string;
          state: { abbrev: string } | undefined;
          owner: { id: string } | undefined;
        }
      | {
          __typename: 'Team';
          id: string;
          sport_id: number;
          gender_id: number;
          level_id: number;
          image: string | undefined;
          name: string;
          mascot: string | undefined;
          short_division_text: string;
          division_text: string;
          web_path: string;
          notification_count: string | undefined;
          gender_sport: string | undefined;
          owner: { id: string } | undefined;
        };
  };
};

export type WebUnfollowUserEntityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type WebUnfollowUserEntityMutation = { unfollowEntity: { id: string } };

export type WebUserEntitiesQueryVariables = Exact<{ [key: string]: never }>;

export type WebUserEntitiesQuery = {
  currentUser: {
    userEntities: Array<{
      id: string;
      entity_type: string;
      role_id: number;
      status_id: number | undefined;
      entity:
        | { __typename: 'Bracket' }
        | { __typename: 'Group' }
        | {
            __typename: 'School';
            id: string;
            image: string | undefined;
            name: string;
            city: string | undefined;
            web_path: string;
            state: { abbrev: string } | undefined;
            owner: { id: string } | undefined;
          }
        | {
            __typename: 'Team';
            id: string;
            sport_id: number;
            gender_id: number;
            level_id: number;
            image: string | undefined;
            name: string;
            mascot: string | undefined;
            short_division_text: string;
            division_text: string;
            web_path: string;
            notification_count: string | undefined;
            gender_sport: string | undefined;
            owner: { id: string } | undefined;
          };
    }>;
  };
};

export type WidgetSearchQueryVariables = Exact<{
  school_group_id?: InputMaybe<Scalars['Int']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;

export type WidgetSearchQuery = {
  teams: {
    edges: Array<{
      node: {
        id: string;
        division_text: string;
        image: string | undefined;
        location_text: string;
        mascot: string | undefined;
        name: string;
      };
    }>;
  };
};

export const EditGameFragmentDoc = `
    fragment editGameFragment on Game {
  date
  is_neutral
  location
  status_id
  secondary_status_id
  game_type {
    id
    name
  }
}
    `;
export const GameTeamFragmentDoc = `
    fragment gameTeamFragment on GameTeam {
  id
  is_home
  team {
    id
    abbrev
    image
    mascot
    name
    location_text
  }
}
    `;
export const AdminTeamsSearchQueryTeamsFragmentDoc = `
    fragment adminTeamsSearchQuery__teamsFragment on TeamConnection {
  nodes {
    id
    abbrev
    division_text
    image
    location_text
    mascot
    name
  }
}
    `;
export const AdminTeamsTeamQueryTeamFragmentDoc = `
    fragment adminTeamsTeamQuery__teamFragment on Team {
  gender {
    name
  }
  level {
    name
  }
  school {
    name
  }
  sport {
    name
  }
  owner {
    id
    username
    image
  }
  user_entities {
    id
    role: roleString
    status_id
    user {
      id
      image
      username
    }
  }
  user_entity_invitation_entities {
    id
    invitation_status
    role
    user_entity_invitation {
      invite_token
      user {
        id
        username
        image
      }
    }
  }
}
    `;
export const SchoolFragmentDoc = `
    fragment schoolFragment on School {
  id
  abbrev
  name
  image
  cover_image
  web_path
  twitter
  facebook
  instagram
  principal
  primary_color
  secondary_color
  athletic_director
  enrollment_count
}
    `;
export const BracketContestTeamFragmentDoc = `
    fragment bracketContestTeam on BracketContestTeam {
  isBye
  isTbd
  isLoser
  isOriginal
  lineScore
  shootoutScore
  seed
  score
  team {
    abbrev
    id
    image
    locationText: location_text
    name
    mascot
  }
  seriesTeamInfo {
    score
    lineScore
    shootoutScore
  }
}
    `;
export const BracketRoundFragmentDoc = `
    fragment bracketRound on BracketRound {
  bracketContests {
    away {
      ...bracketContestTeam
    }
    date
    name
    gameNumber
    home {
      ...bracketContestTeam
    }
    isNeutral
    location
    position
    roundId
    secondaryStatusId
    statusId
    seriesContests {
      date
      statusId
      secondaryStatusId
    }
  }
  defaultName
  endDate
  id
  isLoserRound
  isNeutral
  name
  size
  startDate
}
    ${BracketContestTeamFragmentDoc}`;
export const GameFragmentDoc = `
    fragment gameFragment on GameInterface {
  id
  date
  division_text
  facebook_path
  is_neutral
  location
  medium_status_text
  periods
  results_only
  secondary_status_id
  short_status_text
  status_id
  twitter_path
  web_path
  location_text
  game_type {
    id
  }
  game_setting {
    rule_type_id
  }
  game_type_label
  season {
    id
    name
  }
  short_result(entity_type: Team, entity_id: $team_id)
  score_text(entity_type: Team, entity_id: $team_id)
  featured(entity_type: Team, entity_id: $team_id) {
    is_home
    forfeit
    linescore
    score
    shootout_score
    standing {
      overallRecord
      leagueRecord
    }
  }
  opponent(entity_type: Team, entity_id: $team_id) {
    id
    forfeit
    linescore
    score
    shootout_score
    team {
      id
      abbrev
      image
      mascot
      name
      web_path
    }
  }
}
    `;
export const TeamCoachFragmentDoc = `
    fragment teamCoachFragment on TeamCoach {
  id
  year_hired
  coach_type {
    id
    name
  }
  coach {
    id
    cover_image
    image
    first_name
    last_name
  }
}
    `;
export const TeamFragmentDoc = `
    fragment teamFragment on Team {
  club_house
  cover_image
  facebook
  hashtag
  instagram
  name
  school_id
  stadium_capacity
  twitter
  team_rivals {
    id
    rival {
      id
      image
      mascot
      name
    }
  }
  sport {
    name
  }
}
    `;
export const TeamPlayerFragmentDoc = `
    fragment teamPlayerFragment on TeamPlayer {
  id
  active
  jersey_number
  position {
    id
    abbrev
  }
  position_2 {
    id
    abbrev
  }
  player {
    id
    class_year
    class_year_abbrev
    cover_image
    image
    first_name
    height
    height_text
    last_name
    weight
    mappings {
      displayName
    }
  }
}
    `;
export const UserEntityFragmentDoc = `
    fragment userEntityFragment on UserEntity {
  id
  entity_type
  role_id
  status_id
  entity {
    __typename
    ... on School {
      id
      image
      name
      city
      state {
        abbrev
      }
      owner {
        id
      }
      web_path
    }
    ... on Team {
      id
      sport_id
      gender_id
      level_id
      image
      name
      mascot
      short_division_text
      division_text
      web_path
      gender_sport: gender_sport_slug
      notification_count
      owner {
        id
      }
    }
  }
}
    `;
export const GamePlayerFragmentDoc = `
    fragment gamePlayerFragment on GamePlayerConnection {
  pageInfo {
    endCursor
    hasNextPage
  }
  edges {
    node {
      opponent {
        team {
          abbrev
          image
          name
        }
      }
      game {
        date
        web_path
        home {
          team_id
        }
      }
      stat {
        points_per_game
        ... on BasketballStat {
          free_throws
          free_throw_percentage
          personal_fouls
          two_points_made
          three_points_made
        }
      }
    }
  }
}
    `;
export const AcceptUserEntityInvitationMutationDocument = `
    mutation acceptUserEntityInvitationMutation($inviteToken: String!) {
  acceptUserEntityInvitation(inviteToken: $inviteToken) {
    invitationStatus
    entities {
      entity {
        __typename
        ... on Team {
          id
        }
        ... on School {
          id
        }
      }
    }
  }
}
    `;
export const AddBracketGamesMutationDocument = `
    mutation addBracketGamesMutation($id: ID!, $roundId: BracketRoundIdEnum!, $bracketContests: [BracketContestInputObject!]!) {
  addBracketGames(id: $id, roundId: $roundId, bracketContests: $bracketContests) {
    rounds {
      ...bracketRound
    }
    playInGames
  }
}
    ${BracketRoundFragmentDoc}`;
export const AdminBracketClassificationQueryDocument = `
    query adminBracketClassificationQuery($genderId: Int, $sportId: Int, $state: String) {
  bracketClassifications(state: $state, genderId: $genderId, sportId: $sportId) {
    id
    name
    type: classificationType
    secondaryClassificationType
  }
}
    `;
export const AdminBracketDeleteMutationDocument = `
    mutation adminBracketDeleteMutation($id: ID!) {
  deleteBracket(id: $id) {
    id
  }
}
    `;
export const AdminBracketDetailsMutationDocument = `
    mutation adminBracketDetailsMutation($id: ID, $autoAdvance: Boolean, $syncScores: Boolean, $coverPhotoImage: String, $formatId: Int!, $genderSportSlug: String!, $hideSeedNumbers: Boolean, $logoImage: String, $name: String!, $numberOfTeams: Int!, $showHomeTeamFirst: Boolean, $showThirdPlaceGame: Boolean, $helperText: String, $stateAbbrev: String, $quadrants: Int, $matchupType: BracketMatchupTypeEnum, $bracketClassificationId: Int, $slug: String, $statusId: Int, $playInGames: Int, $nfhsNetworkUrl: String, $gofanTicketingUrl: String, $hasOneLocation: Boolean, $location: String) {
  createEditBracket(
    id: $id
    autoAdvance: $autoAdvance
    syncScores: $syncScores
    coverPhotoImage: $coverPhotoImage
    formatId: $formatId
    genderSportSlug: $genderSportSlug
    hideSeedNumbers: $hideSeedNumbers
    logoImage: $logoImage
    name: $name
    numberOfTeams: $numberOfTeams
    bracketClassificationId: $bracketClassificationId
    showHomeTeamFirst: $showHomeTeamFirst
    showThirdPlaceGame: $showThirdPlaceGame
    playInGames: $playInGames
    helperText: $helperText
    slug: $slug
    stateAbbrev: $stateAbbrev
    matchupType: $matchupType
    quadrants: $quadrants
    statusId: $statusId
    nfhsNetworkUrl: $nfhsNetworkUrl
    gofanTicketingUrl: $gofanTicketingUrl
    hasOneLocation: $hasOneLocation
    location: $location
  ) {
    coverPhotoImage
    id
    logoImage
    statusId
  }
}
    `;
export const AdminBracketPublishMutationDocument = `
    mutation adminBracketPublishMutation($id: ID!) {
  publishBracket(id: $id) {
    statusId
  }
}
    `;
export const AdminBracketQueryDocument = `
    query adminBracketQuery($id: Int) {
  bracket(id: $id) {
    autoAdvance
    syncScores
    coverPhotoImage
    divisionText
    formatId
    matchupType
    quadrants
    quadrantNames {
      index
      name
    }
    genderSport {
      slug
    }
    nfhsNetworkUrl
    gofanTicketingUrl
    hasOneLocation
    location
    hideSeedNumbers
    logoImage
    name
    numberOfTeams
    helperText
    bracketClassificationId
    rounds {
      ...bracketRound
    }
    showHomeTeamFirst
    showThirdPlaceGame
    playInGames
    slug
    state {
      abbrev
    }
    statusId
    userEntityInvitationEntities {
      id
      invitation_status
      role
      user_entity_invitation {
        invite_token
        user {
          id
          username
          image
        }
      }
    }
    users {
      id
    }
  }
}
    ${BracketRoundFragmentDoc}`;
export const AdminBracketRoundsMutationDocument = `
    mutation adminBracketRoundsMutation($id: ID!, $rounds: [BracketRoundInputObject!]!, $quadrants: [BracketQuadrantInputObject!], $statusId: Int) {
  updateBracketRounds(
    id: $id
    rounds: $rounds
    quadrantNames: $quadrants
    statusId: $statusId
  ) {
    statusId
  }
}
    `;
export const AdminBracketsQueryDocument = `
    query adminBracketsQuery($after: String, $seasonName: String, $sportId: Int, $statusId: Int) {
  currentUser {
    brackets(
      after: $after
      seasonName: $seasonName
      sportId: $sportId
      statusId: $statusId
      first: 24
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        name
        statusId
        webPath
        genderSport {
          name
          slug
          key
        }
      }
    }
  }
}
    `;
export const AdminCopyBracketMutationDocument = `
    mutation adminCopyBracketMutation($id: ID!) {
  copyBracket(id: $id) {
    id
  }
}
    `;
export const AdminCreateEditTeamCoachMutationDocument = `
    mutation adminCreateEditTeamCoachMutation($team_id: ID!, $input: TeamCoachInputType!) {
  createEditTeamCoach(team_id: $team_id, input: $input) {
    ...teamCoachFragment
  }
}
    ${TeamCoachFragmentDoc}`;
export const AdminCreateTeamMutationDocument = `
    mutation adminCreateTeamMutation($input: TeamInputType!) {
  createTeam(input: $input) {
    id
    division_text
    games_count
    gender_sport: gender_sport_slug
    has_roster
    has_schedule
    name
    genderSport {
      key
      name
      genderId
      sportId
      gender {
        id
        name
      }
      sport {
        id
        name
      }
    }
    level {
      id
      name
    }
    owner {
      id
      username
      image
    }
    user_entity_invitation_entities {
      id
      invitation_status
      user_entity_invitation {
        invite_token
        email
      }
      entity {
        ... on Team {
          id
        }
      }
    }
    previous_games {
      edges {
        node {
          id
          date
          title_text
          away {
            score
          }
          home {
            score
          }
        }
      }
    }
  }
}
    `;
export const AdminDeleteInvitesMutationDocument = `
    mutation adminDeleteInvitesMutation($inviteIds: [ID!]!, $entityType: String) {
  deleteInvites(ids: $inviteIds, entity_type: $entityType)
}
    `;
export const AdminDeleteTeamCoachMutationDocument = `
    mutation adminDeleteTeamCoachMutation($id: ID!) {
  deleteTeamCoach(id: $id) {
    id
  }
}
    `;
export const AdminGamesCreateEditGamePlayerStatsMutationDocument = `
    mutation adminGamesCreateEditGamePlayerStatsMutation($team_id: ID!, $input: GamePlayerStatsInputType!) {
  createEditGamePlayerStats(team_id: $team_id, input: $input) {
    id
  }
}
    `;
export const AdminGamesCreateEditTeamGameMutationDocument = `
    mutation adminGamesCreateEditTeamGameMutation($team_id: Int!, $input: TeamGameInputType!) {
  createEditTeamGame(team_id: $team_id, input: $input) {
    ...editGameFragment
    featured(entity_type: Team, entity_id: $team_id) {
      ...gameTeamFragment
    }
    opponent(entity_type: Team, entity_id: $team_id) {
      ...gameTeamFragment
    }
  }
}
    ${EditGameFragmentDoc}
${GameTeamFragmentDoc}`;
export const AdminGamesTeamQueryDocument = `
    query adminGamesTeamQuery($team_id: Int, $season_id: Int) {
  team(id: $team_id) {
    team_season(season_id: $season_id) {
      season {
        id
        school_year(team_id: $team_id) {
          full_name
          name
        }
      }
      games {
        ...gameFragment
      }
    }
  }
}
    ${GameFragmentDoc}`;
export const AdminSchoolQueryDocument = `
    query adminSchoolQuery($id: ID!) {
  currentUser {
    school(id: $id) {
      id
      abbrev
      name
      mascot
      image
      cover_image
      web_path
      twitter
      facebook
      instagram
      principal
      primary_color
      secondary_color
      athletic_director
      locationText
      enrollment_count
      owner {
        id
      }
    }
  }
}
    `;
export const AdminSchoolTeamsAdminQueryDocument = `
    query adminSchoolTeamsAdminQuery($id: Int!) {
  school(id: $id) {
    user_entities {
      id
      role: roleString
      role_id
      status_id
      user {
        id
        image
        username
      }
    }
    teams {
      id
      user_entity_invitation_entities {
        id
        invitation_status
        user_entity_invitation {
          email
          invite_token
        }
        entity {
          ... on Team {
            id
          }
        }
      }
    }
  }
}
    `;
export const AdminSchoolTeamsClientQueryDocument = `
    query adminSchoolTeamsClientQuery($id: Int!) {
  school(id: $id) {
    user_entities {
      id
      role: roleString
      role_id
      status_id
      user {
        id
      }
    }
    teams {
      id
      user_entity_invitation_entities {
        id
        invitation_status
        user_entity_invitation {
          email
          invite_token
        }
        entity {
          ... on Team {
            id
          }
        }
      }
    }
  }
}
    `;
export const AdminSchoolTeamsQueryDocument = `
    query adminSchoolTeamsQuery($id: ID!) {
  currentUser {
    school(id: $id) {
      id
      name
      mascot
      state {
        name
        abbrev
      }
      image
      web_path
      location_text
      locationText
      owner {
        id
        username
      }
      teams {
        id
        name
        games_count
        has_roster
        has_schedule
        division_text
        level {
          id
          name
        }
        owner {
          id
          username
          image
        }
        genderSport {
          key
          name
          genderId
          sportId
          gender {
            id
            name
          }
          sport {
            id
            name
          }
        }
        previous_games {
          edges {
            node {
              id
              title_text
              date
              home {
                score
              }
              away {
                score
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const AdminTeamCoachesQueryDocument = `
    query adminTeamCoachesQuery($id: Int) {
  team(id: $id) {
    team_coaches {
      ...teamCoachFragment
    }
  }
}
    ${TeamCoachFragmentDoc}`;
export const AdminTeamInvitationMutationDocument = `
    mutation adminTeamInvitationMutation($entities: [UserEntityInvitationEntityInputType!]!) {
  createUserEntityInvitation(entities: $entities) {
    id
    invite_token
    entities {
      id
      invitation_status
      role
    }
  }
}
    `;
export const AdminTeamQueryDocument = `
    query adminTeamQuery($id: Int) {
  team(id: $id) {
    ...teamFragment
  }
}
    ${TeamFragmentDoc}`;
export const AdminTeamsCreateEditTeamGameMutationDocument = `
    mutation adminTeamsCreateEditTeamGameMutation($team_id: Int!, $input: TeamGameInputType!) {
  createEditTeamGame(team_id: $team_id, input: $input) {
    ...gameFragment
  }
}
    ${GameFragmentDoc}`;
export const AdminTeamsCreateEditTeamPlayerMutationDocument = `
    mutation adminTeamsCreateEditTeamPlayerMutation($team_id: ID!, $input: TeamPlayerInputType!) {
  createEditTeamPlayer(team_id: $team_id, input: $input) {
    ...teamPlayerFragment
  }
}
    ${TeamPlayerFragmentDoc}`;
export const AdminTeamsSearchQueryDocument = `
    query adminTeamsSearchQuery($name: String, $sport_id: Int, $gender_id: Int, $level_id: Int, $state: String, $section_id: Int, $show_all: Boolean) {
  teams(
    name: $name
    sport_id: $sport_id
    gender_id: $gender_id
    level_id: $level_id
    state: $state
    section_id: $section_id
    show_all: $show_all
  ) {
    ...adminTeamsSearchQuery__teamsFragment
  }
}
    ${AdminTeamsSearchQueryTeamsFragmentDoc}`;
export const AdminTeamsTeamPlayersQueryDocument = `
    query AdminTeamsTeamPlayersQuery($id: Int, $season_id: Int) {
  team(id: $id) {
    team_season(season_id: $season_id) {
      season {
        id
        school_year(team_id: $id) {
          full_name
          name
        }
      }
      teamPlayerRosters {
        teamPlayer {
          ...teamPlayerFragment
        }
      }
    }
    gender_id
  }
}
    ${TeamPlayerFragmentDoc}`;
export const AdminTeamsTeamQueryDocument = `
    query adminTeamsTeamQuery($id: Int) {
  team(id: $id) {
    ...adminTeamsTeamQuery__teamFragment
  }
}
    ${AdminTeamsTeamQueryTeamFragmentDoc}`;
export const AdminUpdateSchoolMutationDocument = `
    mutation adminUpdateSchoolMutation($id: ID!, $input: SchoolInputType!) {
  updateSchool(id: $id, input: $input) {
    ...schoolFragment
  }
}
    ${SchoolFragmentDoc}`;
export const AdminUpdateTeamGameResultsMutationDocument = `
    mutation adminUpdateTeamGameResultsMutation($team_id: Int!, $input: TeamGameResultsInputType!) {
  updateTeamGameResults(team_id: $team_id, input: $input) {
    id
    featured(entity_type: Team, entity_id: $team_id) {
      id
    }
    opponent(entity_type: Team, entity_id: $team_id) {
      id
    }
  }
}
    `;
export const AdminUpdateTeamMutationDocument = `
    mutation adminUpdateTeamMutation($team_id: ID!, $input: TeamInputType!) {
  updateTeam(team_id: $team_id, input: $input) {
    ...teamFragment
  }
}
    ${TeamFragmentDoc}`;
export const BracketPickEmsQueryDocument = `
    query bracketPickEmsQuery($bracketId: ID!) {
  currentUser {
    pickEms(bracketId: $bracketId) {
      id
      contest {
        id
        contestParticipants {
          id
          participant {
            __typename
            ... on Team {
              id
            }
          }
        }
      }
      team {
        id
        name
      }
      result
    }
    bracketLeaderboard(bracketId: $bracketId) {
      id
      correctPickEmPercentage
      correctPickEmsCount
      incorrectPickEmsCount
      place
      pointsEarned
      pointsPossible
      tieBreakerPoints
    }
  }
}
    `;
export const CheerGameTeamMutationDocument = `
    mutation cheerGameTeamMutation($id: ID!, $decrement_id: ID) {
  cheerGameTeam(id: $id, decrementId: $decrement_id) {
    id
    cheers
    picks
  }
}
    `;
export const ClaimTeamAsOwnerMutationDocument = `
    mutation claimTeamAsOwnerMutation($id: ID!) {
  claimTeamAsSchoolOwner(team_id: $id, source: "web") {
    id
    owner {
      id
      image
      username
    }
  }
}
    `;
export const CreateContestPickEmMutationDocument = `
    mutation createContestPickEmMutation($id: ID!) {
  createContestPickEm(contestParticipantId: $id) {
    id
    votes
  }
}
    `;
export const CreateContestPickEmsMutationDocument = `
    mutation createContestPickEmsMutation($contestParticipantIds: [ID!]!) {
  createContestPickEms(contestParticipantIds: $contestParticipantIds) {
    id
    contest {
      id
      contestParticipants {
        id
        votes
      }
    }
  }
}
    `;
export const CreateGamePickEmMutationDocument = `
    mutation createGamePickEmMutation($id: ID!) {
  createGamePickEm(input: {gameTeamId: $id}) {
    gameTeams {
      picks
    }
  }
}
    `;
export const CreateTeamRivalMutationDocument = `
    mutation createTeamRivalMutation($id: ID!, $rival_id: ID!) {
  createTeamRival(id: $id, rival_id: $rival_id) {
    id
    rival {
      id
      image
      mascot
      name
    }
  }
}
    `;
export const CreateUserEntityInvitationMutationDocument = `
    mutation createUserEntityInvitationMutation($entities: [UserEntityInvitationEntityInputType!]!) {
  createUserEntityInvitation(entities: $entities) {
    id
    invite_token
  }
}
    `;
export const DeleteContestPickEmsMutationDocument = `
    mutation deleteContestPickEmsMutation($pickEmIds: [ID!]!) {
  deleteContestPickEms(pickEmIds: $pickEmIds) {
    id
    contest {
      id
      contestParticipants {
        id
        votes
      }
    }
  }
}
    `;
export const DeleteOwnerInviteMutationDocument = `
    mutation deleteOwnerInviteMutation($id: ID!, $entityType: String) {
  deleteOwnerInvite(id: $id, entity_type: $entityType)
}
    `;
export const DeleteTeamGameMutationDocument = `
    mutation deleteTeamGameMutation($id: ID!) {
  deleteTeamGame(id: $id) {
    id
  }
}
    `;
export const DeleteTeamMutationDocument = `
    mutation deleteTeamMutation($id: ID!) {
  deleteTeam(id: $id)
}
    `;
export const DeleteTeamOwnerMutationDocument = `
    mutation deleteTeamOwnerMutation($id: ID!) {
  deleteTeamOwner(id: $id) {
    id
  }
}
    `;
export const DeleteTeamPlayerMutationDocument = `
    mutation deleteTeamPlayerMutation($id: ID!) {
  deleteTeamPlayer(id: $id) {
    id
  }
}
    `;
export const DeleteUserEntityMutationDocument = `
    mutation deleteUserEntityMutation($user_id: ID!, $entity_id: ID!, $type: String!) {
  deleteUserEntity(entity_type: $type, entity_id: $entity_id, user_id: $user_id) {
    id
  }
}
    `;
export const GamePickEmQueryDocument = `
    query gamePickEmQuery($contestId: ID!) {
  currentUser {
    pickEm(contestId: $contestId) {
      id
      contest {
        contestParticipants {
          id
          participant {
            __typename
            ... on Team {
              id
              name
            }
          }
        }
      }
      team {
        id
      }
    }
  }
}
    `;
export const GetTeamOwnerEmailQueryDocument = `
    query getTeamOwnerEmailQuery($team_id: Int) {
  team(id: $team_id) {
    owner {
      email
    }
  }
}
    `;
export const PhotoGalleriesSearchQueryDocument = `
    query photoGalleriesSearchQuery($query: String!, $genderSport: GenderSportEnum, $state: StateEnum) {
  photoGalleries(query: $query, genderSport: $genderSport, state: $state) {
    nodes {
      id
      name
      date
      genderSport {
        name
      }
      photographer {
        email
        id
      }
    }
  }
}
    `;
export const RankingTeamsSearchQueryDocument = `
    query rankingTeamsSearchQuery($id: Int!, $name: String!, $organizationId: Int) {
  ranking(id: $id) {
    teamRankings(name: $name, organizationId: $organizationId) {
      nodes {
        id
        filteredChange
        filteredPlace
        points
        record
        streak
        strengthOfSchedule
        team {
          abbrev
          image
          locationText
          mascot
          name
          webPath
        }
        top100Record
      }
    }
  }
}
    `;
export const RegisterUserQueryDocument = `
    query registerUserQuery($email: String, $username: String) {
  user(email: $email, username: $username)
}
    `;
export const RemoveBracketGamesMutationDocument = `
    mutation removeBracketGamesMutation($id: ID!, $roundId: BracketRoundIdEnum!, $positions: [Int!]!) {
  removeBracketGames(id: $id, roundId: $roundId, positions: $positions) {
    rounds {
      ...bracketRound
    }
    playInGames
  }
}
    ${BracketRoundFragmentDoc}`;
export const SearchClientQueryDocument = `
    query searchClientQuery($query: String!, $state: String, $first: Int = 5) {
  search(query: $query, state: $state, first: $first, entityType: [SCHOOL]) {
    nodes {
      __typename
      ... on School {
        id
        name
        image
        location_text
        web_path
      }
    }
  }
}
    `;
export const StandingsSearchQueryDocument = `
    query standingsSearchQuery($genderId: Int, $query: String!, $sportId: Int, $stateAbbrev: String) {
  search(
    entityType: [TEAM, ORGANIZATION]
    genderId: $genderId
    query: $query
    sportId: $sportId
    state: $stateAbbrev
    first: 24
  ) {
    nodes {
      __typename
      ... on Team {
        id
        image
        locationText
        name
        league {
          id
        }
      }
      ... on TeamOrganization {
        id
        fullName
        image
        name
        state {
          name
        }
      }
    }
  }
}
    `;
export const SyncRosterMutationDocument = `
    mutation syncRosterMutation($game_team_id: ID!) {
  syncRoster(game_team_id: $game_team_id)
}
    `;
export const TeamPlayerQueryDocument = `
    query teamPlayerQuery($player_id: Int, $team_id: Int, $season_id: Int, $after: String) {
  team_player(player_id: $player_id, team_id: $team_id) {
    team_player_season(season_id: $season_id) {
      game_players(first: 24, after: $after) {
        ...gamePlayerFragment
      }
    }
  }
}
    ${GamePlayerFragmentDoc}`;
export const UpdateBracketTieBreakerPointsMutationDocument = `
    mutation updateBracketTieBreakerPointsMutation($bracketId: ID!, $tieBreakerPoints: Int!) {
  updateBracketTieBreakerPoints(
    bracketId: $bracketId
    tieBreakerPoints: $tieBreakerPoints
  ) {
    bracketLeaderboard {
      id
      tieBreakerPoints
    }
  }
}
    `;
export const UpdateContestPickEmsMutationDocument = `
    mutation updateContestPickEmsMutation($contestParticipantIds: [ID!]!, $pickEmIds: [ID!]!, $skipCreate: Boolean!, $skipDelete: Boolean!) {
  createContestPickEms(contestParticipantIds: $contestParticipantIds) @skip(if: $skipCreate) {
    id
    contest {
      id
      contestParticipants {
        id
        votes
      }
    }
  }
  deleteContestPickEms(pickEmIds: $pickEmIds) @skip(if: $skipDelete) {
    id
    contest {
      id
      contestParticipants {
        id
        votes
      }
    }
  }
}
    `;
export const UserGamesDocument = `
    query userGames {
  currentUser {
    bracketLeaderboards {
      nodes {
        bracket {
          name
          logoImage
          startDate
          statusId
          webPickEmPath
        }
        place
      }
    }
  }
}
    `;
export const WebClaimEntityMutationDocument = `
    mutation webClaimEntityMutation($entity: ClaimableEntity!) {
  claimEntity(entity: $entity) {
    ...userEntityFragment
  }
}
    ${UserEntityFragmentDoc}`;
export const WebFollowEntityMutationDocument = `
    mutation webFollowEntityMutation($entity: FollowableEntity!) {
  followEntity(entity: $entity) {
    ...userEntityFragment
  }
}
    ${UserEntityFragmentDoc}`;
export const WebUnfollowUserEntityMutationDocument = `
    mutation webUnfollowUserEntityMutation($id: ID!) {
  unfollowEntity(id: $id) {
    id
  }
}
    `;
export const WebUserEntitiesQueryDocument = `
    query webUserEntitiesQuery {
  currentUser {
    userEntities {
      ...userEntityFragment
    }
  }
}
    ${UserEntityFragmentDoc}`;
export const WidgetSearchDocument = `
    query WidgetSearch($school_group_id: Int, $sport_id: Int, $gender_id: Int, $query: String) {
  teams(
    school_group_id: $school_group_id
    sport_id: $sport_id
    gender_id: $gender_id
    name: $query
    first: 10
  ) {
    edges {
      node {
        id
        division_text
        image
        location_text
        mascot
        name
      }
    }
  }
}
    `;
export type Requester<C = {}, E = unknown> = <R, V>(doc: string, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>;
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    acceptUserEntityInvitationMutation(
      variables: AcceptUserEntityInvitationMutationVariables,
      options?: C
    ): Promise<AcceptUserEntityInvitationMutation> {
      return requester<AcceptUserEntityInvitationMutation, AcceptUserEntityInvitationMutationVariables>(
        AcceptUserEntityInvitationMutationDocument,
        variables,
        options
      ) as Promise<AcceptUserEntityInvitationMutation>;
    },
    addBracketGamesMutation(variables: AddBracketGamesMutationVariables, options?: C): Promise<AddBracketGamesMutation> {
      return requester<AddBracketGamesMutation, AddBracketGamesMutationVariables>(
        AddBracketGamesMutationDocument,
        variables,
        options
      ) as Promise<AddBracketGamesMutation>;
    },
    adminBracketClassificationQuery(
      variables?: AdminBracketClassificationQueryVariables,
      options?: C
    ): Promise<AdminBracketClassificationQuery> {
      return requester<AdminBracketClassificationQuery, AdminBracketClassificationQueryVariables>(
        AdminBracketClassificationQueryDocument,
        variables,
        options
      ) as Promise<AdminBracketClassificationQuery>;
    },
    adminBracketDeleteMutation(variables: AdminBracketDeleteMutationVariables, options?: C): Promise<AdminBracketDeleteMutation> {
      return requester<AdminBracketDeleteMutation, AdminBracketDeleteMutationVariables>(
        AdminBracketDeleteMutationDocument,
        variables,
        options
      ) as Promise<AdminBracketDeleteMutation>;
    },
    adminBracketDetailsMutation(variables: AdminBracketDetailsMutationVariables, options?: C): Promise<AdminBracketDetailsMutation> {
      return requester<AdminBracketDetailsMutation, AdminBracketDetailsMutationVariables>(
        AdminBracketDetailsMutationDocument,
        variables,
        options
      ) as Promise<AdminBracketDetailsMutation>;
    },
    adminBracketPublishMutation(variables: AdminBracketPublishMutationVariables, options?: C): Promise<AdminBracketPublishMutation> {
      return requester<AdminBracketPublishMutation, AdminBracketPublishMutationVariables>(
        AdminBracketPublishMutationDocument,
        variables,
        options
      ) as Promise<AdminBracketPublishMutation>;
    },
    adminBracketQuery(variables?: AdminBracketQueryVariables, options?: C): Promise<AdminBracketQuery> {
      return requester<AdminBracketQuery, AdminBracketQueryVariables>(
        AdminBracketQueryDocument,
        variables,
        options
      ) as Promise<AdminBracketQuery>;
    },
    adminBracketRoundsMutation(variables: AdminBracketRoundsMutationVariables, options?: C): Promise<AdminBracketRoundsMutation> {
      return requester<AdminBracketRoundsMutation, AdminBracketRoundsMutationVariables>(
        AdminBracketRoundsMutationDocument,
        variables,
        options
      ) as Promise<AdminBracketRoundsMutation>;
    },
    adminBracketsQuery(variables?: AdminBracketsQueryVariables, options?: C): Promise<AdminBracketsQuery> {
      return requester<AdminBracketsQuery, AdminBracketsQueryVariables>(
        AdminBracketsQueryDocument,
        variables,
        options
      ) as Promise<AdminBracketsQuery>;
    },
    adminCopyBracketMutation(variables: AdminCopyBracketMutationVariables, options?: C): Promise<AdminCopyBracketMutation> {
      return requester<AdminCopyBracketMutation, AdminCopyBracketMutationVariables>(
        AdminCopyBracketMutationDocument,
        variables,
        options
      ) as Promise<AdminCopyBracketMutation>;
    },
    adminCreateEditTeamCoachMutation(
      variables: AdminCreateEditTeamCoachMutationVariables,
      options?: C
    ): Promise<AdminCreateEditTeamCoachMutation> {
      return requester<AdminCreateEditTeamCoachMutation, AdminCreateEditTeamCoachMutationVariables>(
        AdminCreateEditTeamCoachMutationDocument,
        variables,
        options
      ) as Promise<AdminCreateEditTeamCoachMutation>;
    },
    adminCreateTeamMutation(variables: AdminCreateTeamMutationVariables, options?: C): Promise<AdminCreateTeamMutation> {
      return requester<AdminCreateTeamMutation, AdminCreateTeamMutationVariables>(
        AdminCreateTeamMutationDocument,
        variables,
        options
      ) as Promise<AdminCreateTeamMutation>;
    },
    adminDeleteInvitesMutation(variables: AdminDeleteInvitesMutationVariables, options?: C): Promise<AdminDeleteInvitesMutation> {
      return requester<AdminDeleteInvitesMutation, AdminDeleteInvitesMutationVariables>(
        AdminDeleteInvitesMutationDocument,
        variables,
        options
      ) as Promise<AdminDeleteInvitesMutation>;
    },
    adminDeleteTeamCoachMutation(variables: AdminDeleteTeamCoachMutationVariables, options?: C): Promise<AdminDeleteTeamCoachMutation> {
      return requester<AdminDeleteTeamCoachMutation, AdminDeleteTeamCoachMutationVariables>(
        AdminDeleteTeamCoachMutationDocument,
        variables,
        options
      ) as Promise<AdminDeleteTeamCoachMutation>;
    },
    adminGamesCreateEditGamePlayerStatsMutation(
      variables: AdminGamesCreateEditGamePlayerStatsMutationVariables,
      options?: C
    ): Promise<AdminGamesCreateEditGamePlayerStatsMutation> {
      return requester<AdminGamesCreateEditGamePlayerStatsMutation, AdminGamesCreateEditGamePlayerStatsMutationVariables>(
        AdminGamesCreateEditGamePlayerStatsMutationDocument,
        variables,
        options
      ) as Promise<AdminGamesCreateEditGamePlayerStatsMutation>;
    },
    adminGamesCreateEditTeamGameMutation(
      variables: AdminGamesCreateEditTeamGameMutationVariables,
      options?: C
    ): Promise<AdminGamesCreateEditTeamGameMutation> {
      return requester<AdminGamesCreateEditTeamGameMutation, AdminGamesCreateEditTeamGameMutationVariables>(
        AdminGamesCreateEditTeamGameMutationDocument,
        variables,
        options
      ) as Promise<AdminGamesCreateEditTeamGameMutation>;
    },
    adminGamesTeamQuery(variables?: AdminGamesTeamQueryVariables, options?: C): Promise<AdminGamesTeamQuery> {
      return requester<AdminGamesTeamQuery, AdminGamesTeamQueryVariables>(
        AdminGamesTeamQueryDocument,
        variables,
        options
      ) as Promise<AdminGamesTeamQuery>;
    },
    adminSchoolQuery(variables: AdminSchoolQueryVariables, options?: C): Promise<AdminSchoolQuery> {
      return requester<AdminSchoolQuery, AdminSchoolQueryVariables>(
        AdminSchoolQueryDocument,
        variables,
        options
      ) as Promise<AdminSchoolQuery>;
    },
    adminSchoolTeamsAdminQuery(variables: AdminSchoolTeamsAdminQueryVariables, options?: C): Promise<AdminSchoolTeamsAdminQuery> {
      return requester<AdminSchoolTeamsAdminQuery, AdminSchoolTeamsAdminQueryVariables>(
        AdminSchoolTeamsAdminQueryDocument,
        variables,
        options
      ) as Promise<AdminSchoolTeamsAdminQuery>;
    },
    adminSchoolTeamsClientQuery(variables: AdminSchoolTeamsClientQueryVariables, options?: C): Promise<AdminSchoolTeamsClientQuery> {
      return requester<AdminSchoolTeamsClientQuery, AdminSchoolTeamsClientQueryVariables>(
        AdminSchoolTeamsClientQueryDocument,
        variables,
        options
      ) as Promise<AdminSchoolTeamsClientQuery>;
    },
    adminSchoolTeamsQuery(variables: AdminSchoolTeamsQueryVariables, options?: C): Promise<AdminSchoolTeamsQuery> {
      return requester<AdminSchoolTeamsQuery, AdminSchoolTeamsQueryVariables>(
        AdminSchoolTeamsQueryDocument,
        variables,
        options
      ) as Promise<AdminSchoolTeamsQuery>;
    },
    adminTeamCoachesQuery(variables?: AdminTeamCoachesQueryVariables, options?: C): Promise<AdminTeamCoachesQuery> {
      return requester<AdminTeamCoachesQuery, AdminTeamCoachesQueryVariables>(
        AdminTeamCoachesQueryDocument,
        variables,
        options
      ) as Promise<AdminTeamCoachesQuery>;
    },
    adminTeamInvitationMutation(variables: AdminTeamInvitationMutationVariables, options?: C): Promise<AdminTeamInvitationMutation> {
      return requester<AdminTeamInvitationMutation, AdminTeamInvitationMutationVariables>(
        AdminTeamInvitationMutationDocument,
        variables,
        options
      ) as Promise<AdminTeamInvitationMutation>;
    },
    adminTeamQuery(variables?: AdminTeamQueryVariables, options?: C): Promise<AdminTeamQuery> {
      return requester<AdminTeamQuery, AdminTeamQueryVariables>(AdminTeamQueryDocument, variables, options) as Promise<AdminTeamQuery>;
    },
    adminTeamsCreateEditTeamGameMutation(
      variables: AdminTeamsCreateEditTeamGameMutationVariables,
      options?: C
    ): Promise<AdminTeamsCreateEditTeamGameMutation> {
      return requester<AdminTeamsCreateEditTeamGameMutation, AdminTeamsCreateEditTeamGameMutationVariables>(
        AdminTeamsCreateEditTeamGameMutationDocument,
        variables,
        options
      ) as Promise<AdminTeamsCreateEditTeamGameMutation>;
    },
    adminTeamsCreateEditTeamPlayerMutation(
      variables: AdminTeamsCreateEditTeamPlayerMutationVariables,
      options?: C
    ): Promise<AdminTeamsCreateEditTeamPlayerMutation> {
      return requester<AdminTeamsCreateEditTeamPlayerMutation, AdminTeamsCreateEditTeamPlayerMutationVariables>(
        AdminTeamsCreateEditTeamPlayerMutationDocument,
        variables,
        options
      ) as Promise<AdminTeamsCreateEditTeamPlayerMutation>;
    },
    adminTeamsSearchQuery(variables?: AdminTeamsSearchQueryVariables, options?: C): Promise<AdminTeamsSearchQuery> {
      return requester<AdminTeamsSearchQuery, AdminTeamsSearchQueryVariables>(
        AdminTeamsSearchQueryDocument,
        variables,
        options
      ) as Promise<AdminTeamsSearchQuery>;
    },
    AdminTeamsTeamPlayersQuery(variables?: AdminTeamsTeamPlayersQueryVariables, options?: C): Promise<AdminTeamsTeamPlayersQuery> {
      return requester<AdminTeamsTeamPlayersQuery, AdminTeamsTeamPlayersQueryVariables>(
        AdminTeamsTeamPlayersQueryDocument,
        variables,
        options
      ) as Promise<AdminTeamsTeamPlayersQuery>;
    },
    adminTeamsTeamQuery(variables?: AdminTeamsTeamQueryVariables, options?: C): Promise<AdminTeamsTeamQuery> {
      return requester<AdminTeamsTeamQuery, AdminTeamsTeamQueryVariables>(
        AdminTeamsTeamQueryDocument,
        variables,
        options
      ) as Promise<AdminTeamsTeamQuery>;
    },
    adminUpdateSchoolMutation(variables: AdminUpdateSchoolMutationVariables, options?: C): Promise<AdminUpdateSchoolMutation> {
      return requester<AdminUpdateSchoolMutation, AdminUpdateSchoolMutationVariables>(
        AdminUpdateSchoolMutationDocument,
        variables,
        options
      ) as Promise<AdminUpdateSchoolMutation>;
    },
    adminUpdateTeamGameResultsMutation(
      variables: AdminUpdateTeamGameResultsMutationVariables,
      options?: C
    ): Promise<AdminUpdateTeamGameResultsMutation> {
      return requester<AdminUpdateTeamGameResultsMutation, AdminUpdateTeamGameResultsMutationVariables>(
        AdminUpdateTeamGameResultsMutationDocument,
        variables,
        options
      ) as Promise<AdminUpdateTeamGameResultsMutation>;
    },
    adminUpdateTeamMutation(variables: AdminUpdateTeamMutationVariables, options?: C): Promise<AdminUpdateTeamMutation> {
      return requester<AdminUpdateTeamMutation, AdminUpdateTeamMutationVariables>(
        AdminUpdateTeamMutationDocument,
        variables,
        options
      ) as Promise<AdminUpdateTeamMutation>;
    },
    bracketPickEmsQuery(variables: BracketPickEmsQueryVariables, options?: C): Promise<BracketPickEmsQuery> {
      return requester<BracketPickEmsQuery, BracketPickEmsQueryVariables>(
        BracketPickEmsQueryDocument,
        variables,
        options
      ) as Promise<BracketPickEmsQuery>;
    },
    cheerGameTeamMutation(variables: CheerGameTeamMutationVariables, options?: C): Promise<CheerGameTeamMutation> {
      return requester<CheerGameTeamMutation, CheerGameTeamMutationVariables>(
        CheerGameTeamMutationDocument,
        variables,
        options
      ) as Promise<CheerGameTeamMutation>;
    },
    claimTeamAsOwnerMutation(variables: ClaimTeamAsOwnerMutationVariables, options?: C): Promise<ClaimTeamAsOwnerMutation> {
      return requester<ClaimTeamAsOwnerMutation, ClaimTeamAsOwnerMutationVariables>(
        ClaimTeamAsOwnerMutationDocument,
        variables,
        options
      ) as Promise<ClaimTeamAsOwnerMutation>;
    },
    createContestPickEmMutation(variables: CreateContestPickEmMutationVariables, options?: C): Promise<CreateContestPickEmMutation> {
      return requester<CreateContestPickEmMutation, CreateContestPickEmMutationVariables>(
        CreateContestPickEmMutationDocument,
        variables,
        options
      ) as Promise<CreateContestPickEmMutation>;
    },
    createContestPickEmsMutation(variables: CreateContestPickEmsMutationVariables, options?: C): Promise<CreateContestPickEmsMutation> {
      return requester<CreateContestPickEmsMutation, CreateContestPickEmsMutationVariables>(
        CreateContestPickEmsMutationDocument,
        variables,
        options
      ) as Promise<CreateContestPickEmsMutation>;
    },
    createGamePickEmMutation(variables: CreateGamePickEmMutationVariables, options?: C): Promise<CreateGamePickEmMutation> {
      return requester<CreateGamePickEmMutation, CreateGamePickEmMutationVariables>(
        CreateGamePickEmMutationDocument,
        variables,
        options
      ) as Promise<CreateGamePickEmMutation>;
    },
    createTeamRivalMutation(variables: CreateTeamRivalMutationVariables, options?: C): Promise<CreateTeamRivalMutation> {
      return requester<CreateTeamRivalMutation, CreateTeamRivalMutationVariables>(
        CreateTeamRivalMutationDocument,
        variables,
        options
      ) as Promise<CreateTeamRivalMutation>;
    },
    createUserEntityInvitationMutation(
      variables: CreateUserEntityInvitationMutationVariables,
      options?: C
    ): Promise<CreateUserEntityInvitationMutation> {
      return requester<CreateUserEntityInvitationMutation, CreateUserEntityInvitationMutationVariables>(
        CreateUserEntityInvitationMutationDocument,
        variables,
        options
      ) as Promise<CreateUserEntityInvitationMutation>;
    },
    deleteContestPickEmsMutation(variables: DeleteContestPickEmsMutationVariables, options?: C): Promise<DeleteContestPickEmsMutation> {
      return requester<DeleteContestPickEmsMutation, DeleteContestPickEmsMutationVariables>(
        DeleteContestPickEmsMutationDocument,
        variables,
        options
      ) as Promise<DeleteContestPickEmsMutation>;
    },
    deleteOwnerInviteMutation(variables: DeleteOwnerInviteMutationVariables, options?: C): Promise<DeleteOwnerInviteMutation> {
      return requester<DeleteOwnerInviteMutation, DeleteOwnerInviteMutationVariables>(
        DeleteOwnerInviteMutationDocument,
        variables,
        options
      ) as Promise<DeleteOwnerInviteMutation>;
    },
    deleteTeamGameMutation(variables: DeleteTeamGameMutationVariables, options?: C): Promise<DeleteTeamGameMutation> {
      return requester<DeleteTeamGameMutation, DeleteTeamGameMutationVariables>(
        DeleteTeamGameMutationDocument,
        variables,
        options
      ) as Promise<DeleteTeamGameMutation>;
    },
    deleteTeamMutation(variables: DeleteTeamMutationVariables, options?: C): Promise<DeleteTeamMutation> {
      return requester<DeleteTeamMutation, DeleteTeamMutationVariables>(
        DeleteTeamMutationDocument,
        variables,
        options
      ) as Promise<DeleteTeamMutation>;
    },
    deleteTeamOwnerMutation(variables: DeleteTeamOwnerMutationVariables, options?: C): Promise<DeleteTeamOwnerMutation> {
      return requester<DeleteTeamOwnerMutation, DeleteTeamOwnerMutationVariables>(
        DeleteTeamOwnerMutationDocument,
        variables,
        options
      ) as Promise<DeleteTeamOwnerMutation>;
    },
    deleteTeamPlayerMutation(variables: DeleteTeamPlayerMutationVariables, options?: C): Promise<DeleteTeamPlayerMutation> {
      return requester<DeleteTeamPlayerMutation, DeleteTeamPlayerMutationVariables>(
        DeleteTeamPlayerMutationDocument,
        variables,
        options
      ) as Promise<DeleteTeamPlayerMutation>;
    },
    deleteUserEntityMutation(variables: DeleteUserEntityMutationVariables, options?: C): Promise<DeleteUserEntityMutation> {
      return requester<DeleteUserEntityMutation, DeleteUserEntityMutationVariables>(
        DeleteUserEntityMutationDocument,
        variables,
        options
      ) as Promise<DeleteUserEntityMutation>;
    },
    gamePickEmQuery(variables: GamePickEmQueryVariables, options?: C): Promise<GamePickEmQuery> {
      return requester<GamePickEmQuery, GamePickEmQueryVariables>(GamePickEmQueryDocument, variables, options) as Promise<GamePickEmQuery>;
    },
    getTeamOwnerEmailQuery(variables?: GetTeamOwnerEmailQueryVariables, options?: C): Promise<GetTeamOwnerEmailQuery> {
      return requester<GetTeamOwnerEmailQuery, GetTeamOwnerEmailQueryVariables>(
        GetTeamOwnerEmailQueryDocument,
        variables,
        options
      ) as Promise<GetTeamOwnerEmailQuery>;
    },
    photoGalleriesSearchQuery(variables: PhotoGalleriesSearchQueryVariables, options?: C): Promise<PhotoGalleriesSearchQuery> {
      return requester<PhotoGalleriesSearchQuery, PhotoGalleriesSearchQueryVariables>(
        PhotoGalleriesSearchQueryDocument,
        variables,
        options
      ) as Promise<PhotoGalleriesSearchQuery>;
    },
    rankingTeamsSearchQuery(variables: RankingTeamsSearchQueryVariables, options?: C): Promise<RankingTeamsSearchQuery> {
      return requester<RankingTeamsSearchQuery, RankingTeamsSearchQueryVariables>(
        RankingTeamsSearchQueryDocument,
        variables,
        options
      ) as Promise<RankingTeamsSearchQuery>;
    },
    registerUserQuery(variables?: RegisterUserQueryVariables, options?: C): Promise<RegisterUserQuery> {
      return requester<RegisterUserQuery, RegisterUserQueryVariables>(
        RegisterUserQueryDocument,
        variables,
        options
      ) as Promise<RegisterUserQuery>;
    },
    removeBracketGamesMutation(variables: RemoveBracketGamesMutationVariables, options?: C): Promise<RemoveBracketGamesMutation> {
      return requester<RemoveBracketGamesMutation, RemoveBracketGamesMutationVariables>(
        RemoveBracketGamesMutationDocument,
        variables,
        options
      ) as Promise<RemoveBracketGamesMutation>;
    },
    searchClientQuery(variables: SearchClientQueryVariables, options?: C): Promise<SearchClientQuery> {
      return requester<SearchClientQuery, SearchClientQueryVariables>(
        SearchClientQueryDocument,
        variables,
        options
      ) as Promise<SearchClientQuery>;
    },
    standingsSearchQuery(variables: StandingsSearchQueryVariables, options?: C): Promise<StandingsSearchQuery> {
      return requester<StandingsSearchQuery, StandingsSearchQueryVariables>(
        StandingsSearchQueryDocument,
        variables,
        options
      ) as Promise<StandingsSearchQuery>;
    },
    syncRosterMutation(variables: SyncRosterMutationVariables, options?: C): Promise<SyncRosterMutation> {
      return requester<SyncRosterMutation, SyncRosterMutationVariables>(
        SyncRosterMutationDocument,
        variables,
        options
      ) as Promise<SyncRosterMutation>;
    },
    teamPlayerQuery(variables?: TeamPlayerQueryVariables, options?: C): Promise<TeamPlayerQuery> {
      return requester<TeamPlayerQuery, TeamPlayerQueryVariables>(TeamPlayerQueryDocument, variables, options) as Promise<TeamPlayerQuery>;
    },
    updateBracketTieBreakerPointsMutation(
      variables: UpdateBracketTieBreakerPointsMutationVariables,
      options?: C
    ): Promise<UpdateBracketTieBreakerPointsMutation> {
      return requester<UpdateBracketTieBreakerPointsMutation, UpdateBracketTieBreakerPointsMutationVariables>(
        UpdateBracketTieBreakerPointsMutationDocument,
        variables,
        options
      ) as Promise<UpdateBracketTieBreakerPointsMutation>;
    },
    updateContestPickEmsMutation(variables: UpdateContestPickEmsMutationVariables, options?: C): Promise<UpdateContestPickEmsMutation> {
      return requester<UpdateContestPickEmsMutation, UpdateContestPickEmsMutationVariables>(
        UpdateContestPickEmsMutationDocument,
        variables,
        options
      ) as Promise<UpdateContestPickEmsMutation>;
    },
    userGames(variables?: UserGamesQueryVariables, options?: C): Promise<UserGamesQuery> {
      return requester<UserGamesQuery, UserGamesQueryVariables>(UserGamesDocument, variables, options) as Promise<UserGamesQuery>;
    },
    webClaimEntityMutation(variables: WebClaimEntityMutationVariables, options?: C): Promise<WebClaimEntityMutation> {
      return requester<WebClaimEntityMutation, WebClaimEntityMutationVariables>(
        WebClaimEntityMutationDocument,
        variables,
        options
      ) as Promise<WebClaimEntityMutation>;
    },
    webFollowEntityMutation(variables: WebFollowEntityMutationVariables, options?: C): Promise<WebFollowEntityMutation> {
      return requester<WebFollowEntityMutation, WebFollowEntityMutationVariables>(
        WebFollowEntityMutationDocument,
        variables,
        options
      ) as Promise<WebFollowEntityMutation>;
    },
    webUnfollowUserEntityMutation(variables: WebUnfollowUserEntityMutationVariables, options?: C): Promise<WebUnfollowUserEntityMutation> {
      return requester<WebUnfollowUserEntityMutation, WebUnfollowUserEntityMutationVariables>(
        WebUnfollowUserEntityMutationDocument,
        variables,
        options
      ) as Promise<WebUnfollowUserEntityMutation>;
    },
    webUserEntitiesQuery(variables?: WebUserEntitiesQueryVariables, options?: C): Promise<WebUserEntitiesQuery> {
      return requester<WebUserEntitiesQuery, WebUserEntitiesQueryVariables>(
        WebUserEntitiesQueryDocument,
        variables,
        options
      ) as Promise<WebUserEntitiesQuery>;
    },
    WidgetSearch(variables?: WidgetSearchQueryVariables, options?: C): Promise<WidgetSearchQuery> {
      return requester<WidgetSearchQuery, WidgetSearchQueryVariables>(
        WidgetSearchDocument,
        variables,
        options
      ) as Promise<WidgetSearchQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
